.parent-dashboard-payment-detail{
    padding: 0px;
}

.parent-dashboard-payment-detail .parent-dashboard-payment-detail-body{
    padding: 80px 50px;
}



@media (max-width:576px){
    .parent-dashboard-payment-detail .parent-dashboard-payment-detail-body{
        padding: 50px 20px;
    }
}