.MChat-Question-Comp-Container{
    width: 100%;
    opacity: 0;
}

.MChat-Question-Comp-Container.active{
    opacity: 1;
    animation: opacity 0.5s ease-in;
}

@keyframes opacity {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}