.bc-dashboard-home {
  padding: 0px;
}

.bc-dashboard-home .bc-dashboard-home-body {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media(max-width:576px){
  .bc-dashboard-home .bc-dashboard-home-body {
    padding: 40px 10px;
  }
}

/* @media(min-width:1440px){
  .bc-dashboard-home .bc-dashboard-home-body{
    padding: 20px 3vw;
  }
}

@media(min-width:1920px){
  .bc-dashboard-home .bc-dashboard-home-body{
    padding: 20px 5vw;
  }
} */

