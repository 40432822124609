.mchat-intro-screen-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

.mchat-intro-screen-content p{
    font-size:20px;
    font-family:'CocoSharp regular';
    margin:0px
}

.mchat-floated-circle{
    position:absolute;
    bottom:-300px;
}

.mchat-floated-circle.circle-1{
    right: 0px;
    z-index:-1;
}

.mchat-floated-circle.circle-2{
    left: 0px;
    z-index:-1;
}

.mchat-intro-screen-content .mchat-intro-start-btn{
    width: 250px;
    height: 50px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    align-self: center;
    border: none;
    margin-top: 80px;
}

@media (max-width:991px){

    .mchat-intro-screen-content{
        padding: 50px 20px;
        width: 100%;
    }
    
    .mchat-floated-circle{
        max-width:70%;
    }

    .mchat-floated-circle.circle-1{
        right: -150px;
    }
}

@media (max-width:576px){


    .mchat-intro-screen-container{
        height:100%;
    }

    .mchat-intro-screen-content p{
        font-size:16px;
    }

    .mchat-floated-circle.circle-1{
        bottom: -200px;
    }

    .mchat-floated-circle.circle-2{
        top: 0px;
    }
}