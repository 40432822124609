.parent-dashboard-schedule-appointment {
  padding: 0px;
}

.parent-dashboard-schedule-appointment
  .parent-dashboard-schedule-appointment-body {
  padding: 40px;
  width: 60%;
}

.parent-dashboard-schedule-appointment
  .parent-dashboard-schedule-appointment-body
  h1 {
  color: #096E82;
  font-size: 36px;
  margin-bottom: 30px;
}

.parent-dashboard-schedule-appointment
  .parent-dashboard-schedule-appointment-body
  .parent-dashboard-schedule-appointment-btn {
  width: 100%;
  height: 50px;
  margin-top: 30px;
  background-color: #096E82;
  border: none;
  box-shadow: none;
  outline: none;
}

.parent-dashboard-schedule-appointment
  .parent-dashboard-schedule-appointment-body
  .parent-dashboard-schedule-appointment-btn:focus,
.parent-dashboard-schedule-appointment
  .parent-dashboard-schedule-appointment-body
  .parent-dashboard-schedule-appointment-btn:active {
  border: none;
  box-shadow: none;
  outline: none;
}

#schdule-app-modal-fldset {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 10px;
  border-radius: 9px;
  height: 275px;
  overflow-x: hidden;
  overflow-y: scroll;
}

#schdule-app-modal-fldset::-webkit-scrollbar {
  width: 8px;
}

#schdule-app-modal-fldset::-webkit-scrollbar-track {
  background-color: #f0fafb;
  border-radius: 0px 0px 9px 0px;
}

#schdule-app-modal-fldset::-webkit-scrollbar-thumb {
  background-color: #00c8d4;
  border-radius: 12px;
  /* outline: 1px solid slategrey; */
}

#schedule-app-select-btn {
  width: 75px;
  height: 32px;
  margin-top: 0px;
  background-color: #F99B9B;
  font-family: "CocoSharp regular";
  border-radius: 7px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .parent-dashboard-schedule-appointment
    .parent-dashboard-schedule-appointment-body {
    padding: 40px;
    width: 80%;
  }
}

@media (max-width: 425px) {
  .parent-dashboard-schedule-appointment
    .parent-dashboard-schedule-appointment-body {
    padding: 40px 20px;
    width: 100%;
  }

  .parent-dashboard-schedule-appointment
    .parent-dashboard-schedule-appointment-body
    h1 {
    font-size: 28px;
    text-align: center;
  }
}
