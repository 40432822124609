.fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}
.fld_set .legend {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: black;
  font-family: 'CocoSharp bold';
}

.fld_set .input-pass,
.fld_set_fcs .input-pass {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
  font-family: 'CocoSharp regular';
}

.fld_set .input-pass:focus,
.fld_set_fcs .input-pass:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.fld_set .input-pass-icon:hover,
.fld_set_fcs .input-pass-icon:hover {
  cursor: pointer;
}

.fld_set_fcs .legend1 {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
  font-family: 'CocoSharp bold';
}

/* on Focus */
.fld_set_fcs {
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_fcs .input-pass-icon {
  cursor: pointer;
  color: var(--secondary-color);
}
