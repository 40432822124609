.first-user-bubble-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 15px;
}

.first-user-bubble-container .first-user-bubule{
    display: flex;
    align-items: flex-start;
    align-self: flex-end;
}