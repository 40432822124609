.report-gen-intro-modal-comp .modal-content{
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important; 
    border:none;
    width: 100%;
    height:95vh;
}

.report-gen-intro-modal-comp .modal-dialog{
    max-width: 80% !important;
}

@media (max-width:1200px){
    .report-gen-intro-modal-comp .modal-dialog{
        max-width: 90% !important;
    }
}

@media (max-width:1024px){
    .report-gen-intro-modal-comp .modal-dialog{
        max-width: 95% !important;
    }
}