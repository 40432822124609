.dashboard-notes-card-container {
  padding: 10px;
  border: solid 1px gray;
  border-radius: 10px;
  width: 219px;
  height: 207px;
  margin-top: 20px;
  margin-right: 30px;
}

.dashboard-notes-card-container{
  /* font-family: 'montes'; */
}

.dashboard-notes-card-container .note-p {
  color: #AEDFE5;
  text-align: right;
  font-family: 'CocoSharp regular';
  
}

.dashboard-notes-card-container h5{
  font-family: 'CocoSharp regular';
  font-size: 21px;
  color: #096E82;
}

.dashboard-notes-card-container .note-description {
  font-size: 14px;
  margin: 0px;
  margin-top: 15px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}
