.chat-side-drawer-container .ant-drawer-content-wrapper.ant-drawer-content-wrapper{
    width: 300px !important;
}
.chat-side-drawer-container .ant-drawer-content .ant-drawer-header.ant-drawer-header{
    border-radius: 0px;
    font-size: 14px;
    font-family: 'CocoSharp bold';
    display: flex;
    align-items: center;
    padding: 0px !important;
}

.chat-side-drawer-container .ant-drawer-content .ant-drawer-header .ant-drawer-close.ant-drawer-close{
    top: -8px !important;
    right: -8px !important;
}

.chat-side-drawer-container .ant-drawer-content .ant-drawer-header.ant-drawer-header .ant-drawer-title{
    color: white;
}

.chat-side-drawer-container .ant-drawer-content .ant-drawer-body.ant-drawer-body{
    padding: 10px;
}