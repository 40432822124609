.pre-call-questions-side-step-bar .side-step-bar-option{
    width: 60px;
    height: 80px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in;
}

.pre-call-questions-side-step-bar .side-step-bar-option p{
    font-size: 16px;
    font-family: 'CocoSharp bold';
}

.pre-call-questions-side-step-bar .side-step-bar-option:hover{
    cursor: pointer;
    border:solid 1px #9c95d6;
}

.pre-call-questions-side-step-bar .side-step-bar-option.active{
    width: 85px;
    height: 80px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
}


/* color */
.pre-call-questions-side-step-bar #side-step-bar-option-1{
    background-color: rgba(250, 119, 119, 0.5);
}
.pre-call-questions-side-step-bar #side-step-bar-option-2{
    background-color: rgba(250, 119, 119, 0.6);
}
.pre-call-questions-side-step-bar #side-step-bar-option-3{
    background-color: rgba(250, 119, 119, 0.7);
}
.pre-call-questions-side-step-bar #side-step-bar-option-4{
    background-color: rgba(250, 119, 119, 0.8);
}
.pre-call-questions-side-step-bar #side-step-bar-option-5{
    background-color: rgba(250, 119, 119, 0.9);
}
.pre-call-questions-side-step-bar #side-step-bar-option-6{
    background-color: rgba(250, 119, 119, 1);
}


@media(max-width:1024px){
    .pre-call-questions-side-step-bar .side-step-bar-option.active{
        width: 90px;
    }
}