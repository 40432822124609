.Login-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 100px 0px;
}

.Login-Page-Container .Login-Page-Body {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Login-Page-Container .Login-Page-Body h1 {
  font-family: 'CocoSharp bold';
  color: #096E82;
  margin: 0px;
}

.Login-Page-Container .Login-Page-Body hr {
  height: 4px;
  width: 80px;
  background-color: #F99B9B;
}

.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container {
  margin-top: 30px;
  overflow: hidden;
  border-radius: 20px;
  width: 320px;
  height: 50px;
  background-color: #fdfdfd;
  border: solid 1px #F8F1F1;
  display: flex;
  position: relative;
}

.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container.default::after{
  content: "";
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  background-color: #F99B9B;
  color: white !important;
}

.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container.left::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  background-color: #F99B9B;
  animation: leftAnimation 0.5s;
}

.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container.right::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
  bottom: 0px;
  border-radius:20px;
  background-color: #F99B9B;
  animation: rightAnimation 0.5s;
}



.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container .tabs {
  width: 160px;
  color: black;
  text-align: center;
  background: transparent;
  font-family: 'CocoSharp regular';
  border: none;
  z-index: 100;
  animation: defaultColor 0.5s;
}

.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container .active-tab {
  color: white;
  font-family: 'CocoSharp regular';
  animation: activeColor 0.5s;
}


.Login-Page-Container .Login-Page-Body .Login-Page-tabs-container .active-default {
  color: white;
  width: 160px;
  text-align: center;
  background: transparent;
  border: none;
  z-index: 100;
}

.Login-Page-Container .Login-Page-Body .r-span {
  padding-left: 10px;
  font-family: 'CocoSharp bold';
}


.Login-Page-Container .Login-Page-Body .custom-check-box {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 0px;
  border: solid 2px black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Login-Page-Container .Login-Page-Body .custom-check-box .check-mark {
  width: 15px;
  height: 12px;
  border-radius: 50%;
  background-color: #F99B9B;
  /* box-shadow: 0px 0px 0px 2px #F99B9B; */
  border: solid 2px #F99B9B;
  display: none;
}

.Login-Page-Container .Login-Page-Body input[type="checkbox"] {
  display: none;
}

.Login-Page-Container
  .Login-Page-Body
   input[type="checkbox"]:checked +
   .custom-check-box .check-mark {
  display: inline-block;
}

.login-btn {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  background-color: #F99B9B;
  border-radius: 8px;
  color: white;
  align-self: center;
  border: none;
  font-family: 'CocoSharp regular';
}

.Login-Page-Container .Login-Page-Body .login-page-link {
  color: black;
  text-decoration: underline;
  font-family: 'CocoSharp bold';
}

@media (max-width: 768px) {
  .Login-Page-Container .Login-Page-Body {
    width: 60%;
  }
  .Login-Page-Container .Login-Page-Body .Login-Page-tabs-container {
    width: 280px;
  }
  .Login-Page-Container .Login-Page-Body .Login-Page-tabs-container .tabs {
    width: 140px;
  }
}

@media (max-width: 425px) {
  .Login-Page-Container .Login-Page-Body {
    width: 95%;
  }
}


@keyframes leftAnimation {
  0%{
    left: 50%;
    width: 50%;
  }
  40%{
    width: 100%;
  }
  100%{
    right: 0%;
    width: 50%;
  }
}

@keyframes rightAnimation {
  0%{
    right: 50%;
    width: 50%;
  }
  40%{
    width: 100%;
  }
  100%{
    right: 0%;
    width: 50%;
  }
}

@keyframes defaultColor {
  0%{
    color: white;
  }
  100%{
    color: black;
  }
}

@keyframes activeColor {
  0%{
    color: black;
  }
  100%{
    color: white;
  }
}