@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary-color: #F99B9B;
  --secondary-color:#096E82;
  
  --third-color:#00C8D4;
  --text-color: #096E82;
  --footer-color: #030533;

  --toastify-icon-color-error: white !important;
  --toastify-icon-color-success:  white !important;
}


@font-face {
  font-family: "CocoSharp regular";
  src: url("./assets/font/Lato-Regular.ttf");
}

@font-face {
  font-family: "CocoSharp bold";
  src: url("./assets/font/Lato-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "CocoSharp regular", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.animate-left {
  margin-top: 20px;
  height: 40px;
  overflow-x: hidden;
  animation: animateDown infinite 1.5s;
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
html {
  scroll-behavior: smooth;
}


h1,h2{
  color: #096E82 !important; 
}

.sections-horizontal-padding{
  padding: 0px 75px !important;
}

.Toastify__toast--success {
  background: #096E82 !important;
  color: white !important;
}


.Toastify__toast--error {
  background: #F99B9B !important;
  color: white !important;
}

.Toastify__close-button {
  color: white !important;
}

.swal2-icon.swal2-warning{
  border-color: #F99B9B !important;
  color: #F99B9B !important;
}


.swal2-styled.swal2-confirm{
  background-color: #096E82 !important;
}

/* Media */
@media(max-width:1280px){
  .sections-horizontal-padding{
    padding: 0px 40px !important;
  }
}

@media(max-width:1024px){
  .sections-horizontal-padding{
    padding: 0px 30px !important;
  }
}

@media(max-width:576px){
  .sections-horizontal-padding{
    padding: 0px 20px !important;
  }

  h1{
    font-size: 32px !important;
  }

*p{
  font-size: 16px !important;
}
}
