
.insurance-pay-modal-comp .modal-content{
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important; 
    border:none;
  }
  

.insurance-pay-modal-comp h1 {
    color: #096E82;
    font-family: "CocoSharp bold";
    font-weight: bolder;
    font-size: 32px;
    text-align: center;
}

.insurance-pay-modal-comp hr {
    height: 4px;
    margin-top: 0px;
    width: 80px;
    background-color: #AEDFE5;
}

.insurance-pay-modal-comp .pd-insurance-pay-btn{
    width: 100%;
    height: 50px;
    margin-top: 30px;
    background-color: #F99B9B;
    border-radius: 10px;
    color: white;
    align-self: center;
    border: none;
}