.contact-card-container{
    width:100%;
    padding:15px;
    margin-top:20px;
    cursor:pointer;
    display:flex;
    align-items:center;
    border-radius:15px
}

.contact-card-container .sb-avatar .sb-avatar__image{
    border-radius: 50% !important;
}

.contact-card-container:hover{
    background-color: #DEFFF3 !important;
}


@media (max-width:1280px){
    .contact-card-container{
        padding:15px 5px;
    }
}