.child-profile-detail-container {
  padding: 0px;
}

.child-profile-detail-container .child-profile-detail-body {
  padding: 40px;
}

.child-profile-detail-container .child-profile-detail-body .child-profile-create-task-btn{
  width: 150px;
  height: 38px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  padding: 0px 15px;
  color: white;
  align-self: end;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1 {
  height: 310px;
  display: flex;
}

.child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1
  .child-profile-detail-row-1-col-1 {
  height: 100%;
  width: 310px;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1
  .child-profile-detail-row-1-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 30px;
    width: calc(100% - 310px);
}

.child-profile-detail-container
.child-profile-detail-body
.child-profile-detail-row-1
.child-profile-detail-row-1-col-2
.child-profile-edit-btn {
width: 150px;
height: 45px;
margin-top: 0px;
margin-right: 20px;
background-color: #F99B9B;
font-family: 'CocoSharp regular';
border-radius: 6px;
color: white;
align-self: end;
border: none;
display: flex;
justify-content: center;
align-items: center;
}

.child-profile-detail-container
.child-profile-detail-body
.child-profile-detail-row-1
.child-profile-detail-row-1-col-2
.child-profile-view-btn {
  width: 150px;
  height: 45px;
  margin-top: 0px;
  background-color: transparent;
  font-family: 'CocoSharp regular';
  border-radius: 6px;
  color: #096E82;
  align-self: end;
  border: solid 2px #096E82;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 15px;
}


.child-profile-detail-row-2 .child-profile-detail-row-2-tab-bar{
  width:100%;
  height:60px;
  display:flex;
  align-items:flex-end;
}


.child-profile-detail-row-2 .child-profile-detail-row-2-tab-bar .child-profile-detail-row-2-tab-bar-btn{
  border:none;
  font-family:'CocoSharp bold';
  background-color:transparent;
  width: 320px;
  font-size:18px;
  /* padding:0px 35px; */
}

.child-detail-edit-modal .modal-content{
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important; 
  border:none
}

#child-profile-detail-modal-btn{
  width: 100%;
  height: 42px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 7px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

#side-arrows{
  height:35px;
  width:35px;
  cursor:pointer;
  border-radius:50%;
  background-color:var(--primary-color);
  display:flex;
  justify-content:center;
  align-items:center;
}

@media (max-width: 1352px) {

  .child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1 {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1
  .child-profile-detail-row-1-col-1 {
    height: 300px;
  }

  .child-profile-detail-container
    .child-profile-detail-body
    .child-profile-detail-row-1
    .child-profile-detail-row-1-col-2 {
    padding-left: 0px;
    margin-top: 20px;
    width: 100%
  }

  .child-profile-detail-row-2 .child-profile-detail-row-2-tab-bar .child-profile-detail-row-2-tab-bar-btn{
    font-size:16px;
  }
  
}

@media (max-width: 1291px) {

  .child-profile-detail-container
    .child-profile-detail-body
    .child-profile-detail-row-1
    .child-profile-detail-row-1-col-2 {
    width: 100%
  }
}

@media (max-width: 768px) {
  .child-profile-detail-container
    .child-profile-detail-body
    .description-col {
    margin-top: 20px;
  }
  .child-profile-detail-container
    .child-profile-detail-body
    .child-profile-detail-row-1
    .child-profile-detail-row-1-col-2 {
    padding-left: 0px;
    margin-top: 20px;
  }
}

@media(max-width:765px){
  .child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1
  .child-profile-detail-row-1-col-2
  .child-profile-view-btn {
    display: none;
  }
}

@media (max-width:576px){
  .child-profile-detail-container .child-profile-detail-body {
    padding: 40px 10px ;
  }

  .child-profile-detail-container
  .child-profile-detail-body
  .child-profile-detail-row-1
  .child-profile-detail-row-1-col-2
  .child-profile-edit-btn {
  width: 150px;
  height: 45px;
  margin-top: 0px;
  margin-right: 10px;
  background-color: #F99B9B;
  font-family: 'CocoSharp regular';
  border-radius: 6px;
  color: white;
  align-self: end;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

/* 
@media (min-width: 1440px) {
  .child-profile-detail-container .child-profile-detail-body {
    padding: 20px 3vw;
  }
}

@media (min-width: 1920px) {
  .child-profile-detail-container .child-profile-detail-body {
    padding: 20px 5vw;
  }
} */
