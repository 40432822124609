.parent-dashboard-diagnosis-type{
    padding: 0px;
}
.parent-dashboard-diagnosis-type .select-diagnosis-col{
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.parent-dashboard-diagnosis-type .select-diagnosis-col .select-container{
    width: 580px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


@media (max-width:576px){

    .parent-dashboard-diagnosis-type .select-diagnosis-col{
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .parent-dashboard-diagnosis-type .select-diagnosis-col .select-container{
        width: 100%;
    }
}