.pending-profiles-card-container {
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    width: 215px;
    height: 205px;
    margin-top: 20px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  }