.custom-select-date .ant-picker.ant-picker {
  padding: 0px 0px !important;
  font-size: 14px;
  border-radius: 5px;
  border: solid 1px #F99B9B;
}

.custom-select-date .ant-picker .ant-picker-input > input {
  padding: 12px 20px !important;
  font-family: "CocoSharp regular";
}

.custom-select-date .ant-picker .ant-picker-suffix.ant-picker-suffix {
  padding: 12px 20px !important;
  border-left: solid 1px #F99B9B;
}

.ant-picker-content.ant-picker-content {
  font-size: 12px !important;
}

.ant-picker-cell-inner.ant-picker-cell-inner {
  font-size: 12px;
}

@media (max-width: 576px) {
  .custom-select-date {
    width: 100% !important;
  }

  .custom-select-date .ant-picker.ant-picker {
    width: 100% !important;
  }
}
