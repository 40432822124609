.dashboard-home-card-container {
  width: 25vw;
  height: 18%;
  margin-top: 30px;
  padding-left: 10px;
  display: flex;
  box-shadow: 1px 1px 6px 1px rgb(230, 230, 230);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.dashboard-home-card-container.second-row {
  margin-top: 60px;
}

.dashboard-home-card-container .disabled-card {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}

.dashboard-home-card-container .dashboard-home-card-col-1 {
  width: 55%;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
}

.dashboard-home-card-container .dashboard-home-card-col-1 h6 {
  font-weight: 500;
  font-size: 20px;
  font-family: "CocoSharp bold";
  color: #096E82 !important;
}
.dashboard-home-card-container .dashboard-home-card-col-1 hr {
  border-bottom: solid 3px var(--primary-color);
  width: 100px;
  margin-top: 0px;
}

.dashboard-home-card-container
  .dashboard-home-card-col-1
  .dashboard-home-card-btn {
  padding: 12px 0px;
  width: 120px;
  text-align: center;
  background-color: white;
  font-family: "CocoSharp bold";
  color: #096E82 !important;
  -webkit-box-shadow: 0px 4px 9px -2px rgb(184, 184, 184);
  -moz-box-shadow: 0px 4px 9px -2px rgb(184, 184, 184);
  box-shadow: 0px 4px 9px -2px rgba(184, 184, 184);
  border: none;
  font-size: 12px;
  border-radius: 8px;
}

.dashboard-home-card-container
  .dashboard-home-card-col-1
  .dashboard-home-card-btn:hover {
  text-decoration: none;
}

.dashboard-home-card-container .dashboard-home-card-col-2 {
  width: 45%;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  justify-content: flex-end;
}

.dashboard-home-card-container .dashboard-home-card-col-2 img {
  border-radius: 0px 0px 0px 0px;
}

@media (max-width: 1600px) {
  .dashboard-home-card-container {
    width: 24vw;
  }
}

@media (max-width: 1600px) {
  .dashboard-home-card-container {
    width: 24vw;
  }
}

@media (max-width: 1200px) {
  .dashboard-home-card-container {
    width: 24.5vw;
  }
}

@media (max-width: 1024px) {
  .dashboard-home-card-container {
    width: 35vw;
  }
  .dashboard-home-card-container.second-row {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .dashboard-home-card-container {
    width: 42vw;
  }
  .dashboard-home-card-container.second-row {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .dashboard-home-card-container {
    width: 100vw;
  }
}

@media (min-width: 2100px) {
  .dashboard-home-card-container {
    width: 20vw;
    height: 20%;
  }


  .dashboard-home-card-container {
    margin-right: 50px;
  }
}
