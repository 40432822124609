.parent-dashboard-chats-container {
  padding: 0px;
}

.parent-dashboard-chats-container
  .parent-dashboard-chats-body {
  padding: 40px;
}

.parent-dashboard-chats-container
  .parent-dashboard-chats-body
  h1 {
  font-size: 36px;
  font-weight: 700;
  color: #096E82;
}

@media(max-width: 576px){
  .parent-dashboard-chats-container
  .parent-dashboard-chats-body {
    padding: 40px 10px;
  }
}

@media (max-width: 425px) {

  .parent-dashboard-chats-container
    .parent-dashboard-chats-body
    h1 {
    font-size: 32px;
  }

  .parent-dashboard-chats-container
    .parent-dashboard-chats-body
    h2 {
    font-size: 24px;
  }
}
