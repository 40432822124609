.Schedule-First-Meeting-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 120px 0px;
}

.Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body h1 {
  color: #096E82;
  font-weight: 600;
  margin: 0px;
  font-size: 36px;
  text-align: center;
}

.Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body hr {
  height: 4px;
  width: 80px;
  background-color: #F99B9B;
}

.Schedule-First-Meeting-Page-Container
  .Schedule-First-Meeting-Page-Body
  .Schedule-First-Meeting-btn {
  width: 100%;
  height: 50px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
}

#clinicians-list-modal-fldset{
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 10px;
  border-radius: 9px;
  height: 275px;
  overflow-x: hidden;
  overflow-y: scroll;
}



#clinicians-list-modal-fldset::-webkit-scrollbar {
  width: 8px;
}

#clinicians-list-modal-fldset::-webkit-scrollbar-track {
  background-color: #f0fafb;
  border-radius: 0px 0px 9px 0px;
}

#clinicians-list-modal-fldset::-webkit-scrollbar-thumb {
  background-color: #DEFFF3;
  border-radius: 12px;
  /* outline: 1px solid slategrey; */
}

#clinicians-list-select-btn {
  width: 75px;
  height: 32px;
  margin-top: 0px;
  background-color: #096E82;
  font-family: "CocoSharp regular";
  border-radius: 7px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 20px;
}


@media (max-width: 768px) {
  .Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body {
    width: 60%;
  }
}

@media (max-width: 425px) {
  .Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body {
    width: 95%;
  }

  .Schedule-First-Meeting-Page-Container .Schedule-First-Meeting-Page-Body h1 {
    font-size: 32px;
  }
}
