.virtual-session-container {
    padding: 0px;
}
  
.virtual-session-container
.virtual-session-body {
    padding: 40px;
}

.virtual-session-container
.virtual-session-body
.agora-video-component-container {
    width: 100%;
}

  /* For First View */
  .agora-video-component-container .agora-video-component-row {
    width: 100%;
  }
  
  
  .agora-video-component-container
    .agora-video-component-row
    .local-user-container {
    width: 130px;
    height: 130px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    z-index: 1;
  }


  .virtual-session-call-btns-container{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
  }


  .virtual-session-call-btns-container .call-btn {
    font-size: 22px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media(max-width: 576px){
    .virtual-session-container
    .virtual-session-body {
      padding: 40px 10px;
    }
  }
  
  @media (max-width: 425px) {
    .agora-video-component-container
      .agora-video-component-row-2
      .extend-call-btn {
      top: 25px;
      left: 10px;
      width: 130px;
    }
  
    .agora-video-component-container
      .agora-video-component-row-2
      .remote-user-container {
      width: 120px;
      height: 120px;
      top: 25px;
      right: 10px;
    }
  }


  

  