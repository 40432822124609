.pre-call-questionnaire-main-container-1{
    display: none;
}

#floated-action-btn{
    display: none;
}

#doc-close-icon{
    position:absolute;
    top:-8px;
    right:-8px;
    font-size:18px;
    background-color:white;
    border-radius:50%;
    padding:2px;
    border:solid 1px lightgray;
    transition: all 0.2s ease-in;
}

#doc-close-icon:hover{
    cursor: pointer;
    box-shadow:0px 5px 10px 0px #cecece;
}

.info-detail-modal .modal-content{
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important; 
    border:none
  }

  #info-modal-btn{
    width: 100%;
    height: 42px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 7px;
    color: white;
    align-self: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media(max-width:1200px){
    #floated-action-btn{
        display: block;
    }
}

@media(max-width:768px){
    .PreCallQuestionIndex-col-1,
    .PreCallQuestionIndex-col-3{
        display: none;
    }
}


@media(max-width:765px){
    .pre-call-questionnaire-main-container-1{
        display: block;
        background-color:var(--secondary-color);
    }
    .pre-call-questionnaire-main-container-2{
        display: none;
    }
    #floated-action-btn{
        display: none;
    }
}