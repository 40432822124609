.child-detail-modal-container .modal-lg{
  max-width: 90%;
  display: flex;
  justify-content: center;
}

.child-detail-modal.modal-content {
    background-color: white !important;
    border: none;
    padding: 35px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 35px;
    width: 90%;
  }

.child-detail-modal.modal-content .modal-header{
  padding: 0px 10px;
  border:none;
  border-radius: 0px;
}

.child-detail-modal.modal-content .modal-header h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  margin: 0px;
  color: #096E82;
}

.child-detail-modal.modal-content .modal-header .select-previous{
  font-size: 18px;
  margin: 0px;
  font-family: 'CocoSharp regular';
  cursor: pointer;
}

.child-detail-modal.modal-content .modal-header .select-next{
  font-size: 18px;
  margin: 0px;
  font-family: 'CocoSharp regular';
  cursor: pointer;
}

/* Forms css */
.child-detail-modal-container .pre-call-form-1-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-1-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-1-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

/* Form 2 css */
.child-detail-modal-container .pre-call-form-2-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-2-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-2-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}


/* Form 3 css */
.child-detail-modal-container .pre-call-form-3-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-3-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-3-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}


/* First table */
#custom-table {
  border: 2px solid black;
  border-radius: 11px;
  border-spacing: 0;
  /* overflow: hidden; */
  border-collapse: separate;
}

#custom-table th{
  font-size: 20px;
  font-family: 'CocoSharp bold';
}

#custom-table th,#custom-table td{
    /* width: 30px;  */
    padding: 8px;
    text-align: center;
    border-bottom: 2px solid black; 
    border-right: 2px solid black;
}

#custom-table td{
  font-size: 16px;
  font-family: 'CocoSharp regular';
}

#custom-table tr:last-child > td{
    border-bottom: none;
}

#custom-table td:nth-child(4),#custom-table th:nth-child(4){
    border-right: none;
}

/* Second table */
#custom-table-1 {
    border: 2px solid black;
    border-radius: 11px;
    border-spacing: 0;
    /* overflow: hidden; */
    border-collapse: separate;
  }
  
  #custom-table-1 th{
    font-size: 20px;
    font-family: 'CocoSharp bold';
  }

  #custom-table-1 th,#custom-table-1 td{
      width: 30px; 
      padding: 8px;
      border-bottom: 2px solid black; 
      border-right: 2px solid black;
  }
  
  #custom-table-1 tr:last-child > td{
      border-bottom: none;
  }

  #custom-table-1 td{
    font-size: 16px;
    font-family: 'CocoSharp regular';
  }
  
  
  #custom-table-1 td:nth-child(3),#custom-table-1 th:nth-child(3){
      border-right: none;
  }


/* Form 4 css */
.child-detail-modal-container .pre-call-form-4-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-4-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-4-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}


/* Form 5 css */
.child-detail-modal-container .pre-call-form-5-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-5-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-5-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}


/* Form 6 css */
.child-detail-modal-container .pre-call-form-6-detail-container h3{
  font-size: 25px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-6-detail-container .form-fld-title{
  font-size:16px;
  margin-bottom:0px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.child-detail-modal-container .pre-call-form-6-detail-container .form-fld-detail{
  font-size:18px;
  margin-top:0px;
  font-family: 'CocoSharp bold';
  color: #096E82;
}



@media (max-width:1024px) {
  .child-detail-modal-container .modal-lg{
    max-width: 100%;
  }
  
}