.dashboard-article-card-container {
    padding: 10px;
    border: solid 1px gray;
    width: 225px;
    height: 160px;
    margin-top: 20px;
    margin-right: 20px;
  }
  
  .dashboard-article-card-container .note-p {
    color: #AEDFE5;
    text-align: right;
  }
  
  .dashboard-article-card-container .note-description {
    font-size: 12px;
    margin: 0px;
  }
  
  