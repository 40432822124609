.dashboard-select-child-container {
    padding: 0px;
  }
  
  .dashboard-select-child-container
    .dashboard-select-child-body {
    padding: 40px;
  }
  
  .dashboard-select-child-container
    .dashboard-select-child-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .dashboard-select-child-container
    .dashboard-select-child-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }

  .dashboard-select-child-container
    .dashboard-select-child-body
    .child-card-container {
      height:100px;
      width:100%;
      padding:0px 20px;
      margin-bottom:25px;
      margin-top:10px;
      display:flex;
      align-items:center;
      justify-content:space-between;
      border: solid 1px #096E82;
      border-radius: 10px;
      box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  }

  .dashboard-select-child-container
    .dashboard-select-child-body
    .child-card-select-btn {
      width: 100px;
      height: 40px;
      margin-top: 0px;
      background-color: #096E82;
      font-family: "CocoSharp regular";
      border-radius: 7px;
      color: white;
      border: none;
  }

  @media(max-width: 576px){
    .dashboard-select-child-container
    .dashboard-select-child-body {
      padding: 40px 10px;
    }
  }
  
  @media (max-width: 425px) {
    .dashboard-select-child-container
      .dashboard-select-child-body
      h1 {
      font-size: 32px;
    }
  
    .dashboard-select-child-container
      .dashboard-select-child-body
      h2 {
      font-size: 24px;
    }
  }

  