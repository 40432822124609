.navbar-container {
  background-color:white;
  padding-top:0px;
  margin:0px;
  height:100px
}

.navbar-light .navbar-toggler-icon {
  width: 25px !important;
  height: 25px !important;
}

.link {
  position: relative;
  color: #333333;
  color: var(--text-color);
  font-size: 14px;
  font-family: 'CocoSharp bold';
  margin-left: 55px;
}

.link:hover {
  text-decoration: none;
  color: black;
}

.link:last-child {
  margin-right: 0px;
}

 .link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  transition: all 0.5s ease-in;
}

.navbar-right-col{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-right-col p{
  font-size: 14px;
  font-family: 'CocoSharp bold';
  color: #333333;
  margin: 0px;
  cursor: pointer;
}

.navbar-auth-btn{
  width: 120px;
  height: 45px;
  background-color: var(--third-color);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'CocoSharp bold';
  font-size: 14px;
  color: white;
  margin-left: 55px;
}

.navbar-auth-btn:hover{
  color: white;
}

@media (max-width:1200px){

  .link {
    margin-left: 35px; 
  }

  .navbar-auth-btn{
    margin-left:35px;
  }
}

@media (max-width:1080px){
  .link {
    margin-left: 20px;
  }

  .navbar-auth-btn{
    margin-left:20px;
  }
}

@media (max-width:991px){
  .navbar-light .navbar-toggler {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-container {
    height:auto;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .link {
    margin-left: 0px;
    margin-top: 15px;
  }

  .navbar-right-col{
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-right-col p{
    margin-top: 15px;
  }

  .navbar-auth-btn{
    margin-left: 0px;
    margin-top: 20px;
  }
}
