.tele-peds-asd-container{
    width: 100%;
    color: #096E82;
}

.tele-peds-asd-container .tele-peds-asd-title{
    font-size: 22px !important;
    margin: 0px;
    font-family: 'CocoSharp bold';
    text-align: center;
}

.tele-peds-asd-container .tele-peds-asd-sub-title{
    font-size: 14px !important;
    margin: 0px;
    font-family: 'CocoSharp regular';
    text-align: center;
}