.download_doc_container {
    padding: 10px 0px;
    border: dashed 2px #030533;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .download_doc_container:hover {
    cursor: pointer;
  }