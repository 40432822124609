.upload_file_container {
  padding: 10px 0px;
  border: dashed 2px #030533;
  width: 100%;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload_file_container:hover {
  cursor: pointer;
}
