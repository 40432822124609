.call-btns-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 100;
}

.call-btns-container .call-btn {
  font-size: 22px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
