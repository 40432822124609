.agora-video-component-container {
  width: 100%;
}

.agora-video-component-container .agora-video-component-couter {
  width: 260px;
  height: 55px;
  border-radius: 10px;
  padding: 18px 22px;
  background-color: #096E82;
  margin-bottom: 15px;
}

.agora-video-component-container .agora-video-component-couter p {
  color: white;
  margin: 0px;
  line-height: 1;
  font-family: "CocoSharp regular";
  font-size: 16px;
}

/* For First View */
.agora-video-component-container .agora-video-component-row {
  width: 100%;
}

.agora-video-component-container .agora-video-component-row .extend-call-btn {
  position: absolute;
  bottom: 35px;
  left: 35px;
  background-color: #F99B9B;
  width: 150px;
  height: 53px;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
  color: white;
  font-family: "CocoSharp regular";
  z-index: 1000 !important;
}

.agora-video-component-container
  .agora-video-component-row
  .local-user-container {
  width: 130px;
  height: 130px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  z-index: 1;
}

/* For second view */
.agora-video-component-container .agora-video-component-row-2 {
  width: 100%;
  z-index: 1000;
}

.agora-video-component-container .agora-video-component-row-2 .extend-call-btn {
  position: absolute;
  top: 35px;
  left: 35px;
  background-color: #F99B9B;
  width: 150px;
  height: 53px;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 10px;
  color: white;
  font-family: "CocoSharp regular";
  z-index: 1000;
}

.agora-video-component-container
  .agora-video-component-row-2
  .local-user-container {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 1;
}

@media (max-width: 425px) {
  .agora-video-component-container
    .agora-video-component-row-2
    .extend-call-btn {
    top: 25px;
    left: 10px;
    width: 130px;
  }

  .agora-video-component-container
    .agora-video-component-row-2
    .remote-user-container {
    width: 120px;
    height: 120px;
    top: 25px;
    right: 10px;
  }
}
