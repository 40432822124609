.chat-footer-container{
    width: 100%;
    height: 45px;
    border-radius: 15px;
    border: solid 1px rgba(150,169,186,0.7);
    display: flex;
    align-items: center;
}

.chat-footer-container .chat-footer-attachment-icon{
    width:60px;
    display:flex;
    justify-content:flex-end
}

.chat-footer-container .chat-footer-voice-icon{
    width:60px;
    display:flex;
    justify-content:center
}

.chat-footer-container .chat-footer-input-container{
    width:calc(100% - 120px);
    display:flex
}

.chat-footer-container input{
    border:none;
    width:100%;
}



.chat-footer-container .send-message-btn{
    height: 43px;
    border: none;
    width: 130px;
    border-radius: 0px 14px 14px 0px;
    color: white;
    font-family: 'CocoSharp bold';
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:576px){
    .chat-footer-container .chat-footer-attachment-icon{
        width:40px;
        justify-content:center
    }
    
    .chat-footer-container .chat-footer-voice-icon{
        width:30px;
    }

    .chat-footer-container .chat-footer-input-container{
        width:calc(100% - 60px);
        display:flex
    }
    
    .chat-footer-container .send-message-btn{
        width: 100px;
        border-radius: 0px 12px 12px 0px;
    }
}