.dashboard-appointments-card-container * {
  color: #096E82;
}
.dashboard-appointments-card-container {
  padding: 18px 20px 10px 13px;
  border: solid 1px #096E82;
  border-radius: 8px;
  width: 220px;
  height: 170px;
  margin-top: 35px;
  margin-right: 15px;
}

.dashboard-appointments-card-container h5 {
  font-size: 23px;
  font-family: 'CocoSharp regular';
  margin: 0px;
}

.dashboard-appointments-card-container .appointment-date,
.dashboard-appointments-card-container .appointment-time {
  font-size: 15px;
  font-family: 'CocoSharp regular';
  color: #096E82;
}

.dashboard-appointments-card-container .appointment-time{
  margin: 0px;
}

.dashboard-appointments-card-container .appointment-date {
  margin-top: 19px;
  margin-bottom: 0px;
}

.dashboard-appointments-card-container .appointment-card-status {
  font-size: 16px;
  font-family: 'CocoSharp regular';
}

.dashboard-appointments-card-container .appointment-card-reschedule {
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 6px;
  background-color: #F99B9B;
  font-family: 'CocoSharp regular';
  color: white;
}

.dashboard-appointments-card-container .appointment-card-reschedule:hover {
  text-decoration: none;
}

.dashboard-appointments-card-container .appointment-card-cancel {
  color: #096E82;
  font-size: 16px;
  font-family: 'CocoSharp regular';
}

.dashboard-appointments-card-container .appointment-card-cancel:hover {
  cursor: pointer;
  text-decoration: none;
}


#appointment-card-reschedule-btn2{
  width: 100%;
  height: 53px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #096E82;
  border: none;
  box-shadow: none;
  outline: none;
  color: white;
}


.modal-content {
  border-radius: 20px;
}
