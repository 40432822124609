.procedures-intro-container{
    width: 100%;
}

.procedures-intro-container .procedures-intro-title{
    font-size: 18px !important;
    font-family: 'CocoSharp regular';
    font-weight: 600;
    margin: 0px;
}

.procedures-intro-container .procedures-intro-detail{
    font-size: 14px !important;
    font-family: 'CocoSharp regular';
    margin: 0px;
    margin-top: 20px !important;
}

.procedures-intro-container .procedures-intro-detail .procedures-intro-therapist-name-input{
    border: none;
    border-bottom: solid 1px gray;
    width: 100px;
    margin: 0px 5px;
    height: 15px;
}

.procedures-intro-container .procedures-intro-form-container{
    margin-top: 20px;
}

.procedures-intro-container .procedures-intro-form-container .procedures-intro-form .procedures-intro-form-fld{
    border: none;
    border-bottom: solid 2px black;
    max-width: 300px;
    margin: 0px 5px;
    height: 18px;
    margin-bottom: 15px;
}
