.mchat-intro-screen-container{
    width: 100%;
    height: 950px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.mchat-intro-screen-container .mchat-intro-screen-body{
    width: 60%;
}

.mchat-intro-screen-container .mchat-floated-circle{
    position:absolute;
    bottom:-100px;
}

.mchat-intro-screen-container .mchat-floated-circle.circle-1{
    right: 0px;
    z-index:-1;
}

.mchat-intro-screen-container .mchat-floated-circle.circle-2{
    left: 0px;
    z-index:100;
}

@media (max-width:991px){

    .mchat-intro-screen-container .mchat-intro-screen-body{
        width: 90%;
    }
    

    .mchat-intro-screen-container .mchat-floated-circle{
        max-width:70%;
    }

    .mchat-intro-screen-container .mchat-floated-circle.circle-1{
        right: -150px;
    } 
}

@media (max-width:576px){


    .mchat-intro-screen-container{
        height:100%;
    }

    .mchat-intro-screen-container .mchat-intro-screen-body{
        width: 100%;
    }

    .mchat-intro-screen-container .mchat-floated-circle.circle-1{
        bottom: -200px;
    }

    .mchat-intro-screen-container .mchat-floated-circle.circle-2{
        top: 0px;
    }
}