.bc-dashboard-appointments-container {
    padding: 0px;
  }
  
  .bc-dashboard-appointments-container
    .bc-dashboard-appointments-body {
    padding: 40px;
  }
  
  .bc-dashboard-appointments-container
    .bc-dashboard-appointments-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .bc-dashboard-appointments-container
    .bc-dashboard-appointments-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table table
  {
    border: solid 2px #096E82;
    border-radius: 15px;
    margin-top: 20px;
    width: 100%;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius: 15px;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table-container table > thead > tr:first-child th{
    background-color: #F2F0FF;
    font-family: 'CocoSharp bold';
    font-size: 20px;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table-container table > thead > tr:last-child th:last-child{
    border-top-right-radius: 15px;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table-tbody > tr > td{
    border-top: solid 1px #096E82;
    border-bottom: 0px;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-table
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-start-session-btn {
    width: 120px;
    height: 40px;
    margin-top: 0px;
    background-color: #096E82;
    border-radius: 8px;
    color: white;
    border: none;
    text-align: center;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-start-session-btn1 {
    width: 150px;
    height: 40px;
    margin-top: 0px;
    background-color: white;
    border-radius: 8px;
    color: #096E82;
    border: solid 2px #096E82;
    text-align: center;
  }

  .bc-dashboard-appointments-container .bc-dashboard-appointments-body .rbt-appointments-start-session-btn2 {
    width: 120px;
    height: 40px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    border: none;
    text-align: center;
  }

  #upload-report-modal-btn {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    background-color: #096E82;
    border-radius: 10px;
    color: white;
    align-self: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    border-radius: 20px;
  }
  
  @media(max-width: 576px){
    .bc-dashboard-appointments-container
    .bc-dashboard-appointments-body {
      padding: 40px 10px;
    }
  }
  
  @media (max-width: 425px) {
    .bc-dashboard-appointments-container
      .bc-dashboard-appointments-body
      h1 {
      font-size: 32px;
    }
  
    .bc-dashboard-appointments-container
      .bc-dashboard-appointments-body
      h2 {
      font-size: 24px;
    }
  }

  