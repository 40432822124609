.fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}
.fld_set .legend {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: #030533;
}

.fld_set_fcs .legend1  {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
}

.fld_set .text-area {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
}

.fld_set .text-area:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.fld_set_fcs{
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_fcs .text-area:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}


.fld_set .text-area.form-control, .fld_set_fcs .text-area.form-control{
  padding: 0px 0.75rem;
}