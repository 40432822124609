.pre-call-questions-top-step-bar{
    padding: 10px 30px;
    margin-top: 25px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
}

.pre-call-questions-top-step-bar .top-step-bar-option:hover{
    cursor: pointer;
}

.pre-call-questions-top-step-bar .save-link:hover{
    cursor: pointer;
}

@media(max-width:1440px){
    .pre-call-questions-top-step-bar{
        font-size: 14px !important;
    }
}


@media(max-width:1200px){
    .pre-call-questions-top-step-bar{
        font-size: 12px !important;
    }

    .pre-call-questions-top-step-bar #save-buttons, .pre-call-questions-top-step-bar #bar{
        display: none !important;
    }
}
