.phn_fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
  background-color: transparent;
}

.phn_fld_set .legend {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: black;
  font-family: 'CocoSharp bold';
}

/* on Focus */
.phn_fld_set_fcs {
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.phn_fld_set_fcs .legend1 {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
  font-family: 'CocoSharp bold';
}

/* error */
.phn_fld_set_err {
  width: 100%;
  border: solid 2px red;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.phn_fld_set_err .legend2 {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: red !important;
  font-family: 'CocoSharp bold';
}

.phn_fld_set .PhoneInputInput,
.phn_fld_set_fcs .PhoneInputInput,
.phn_fld_set_err .PhoneInputInput {
  padding: 0px 12px 6px 6px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent;
  line-height: 1.5px !important;
  height: calc(1.5em + 0.75rem + 2px);
  font-family: 'CocoSharp regular';
}

.PhoneInput {
  margin-left: 12px;
  font-family: 'CocoSharp regular';
}
