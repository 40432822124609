.fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set .legend {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: black;
}

.fld_set .select,
.fld_set_fcs .select,
.fld_set_err .select {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
}

/* on Focus */
.fld_set_fcs {
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_fcs .legend1 {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
}

/* For Error */
.fld_set_err {
  width: 100%;
  border: solid 2px red;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_err .legend2 {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: red !important;
}

/* .fld_set select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='36' viewBox='0 0 20 20' width='36' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -6px;
} */

.fld_set select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 40%, #030533 50%),
    linear-gradient(135deg, #030533 40%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em - 5px),
    calc(100% - 10px) calc(1em - 5px);
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'CocoSharp regular' !important;
}

.fld_set_fcs select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(
      45deg,
      transparent 40%,
      var(--secondary-color) 50%
    ),
    linear-gradient(135deg, var(--secondary-color) 40%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em - 5px),
    calc(100% - 10px) calc(1em - 5px);
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'CocoSharp regular' !important;
}

.fld_set_err select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 40%, red 50%),
    linear-gradient(135deg, red 40%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em - 5px),
    calc(100% - 10px) calc(1em - 5px);
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'CocoSharp regular' !important;
}
