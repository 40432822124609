.mchat-result-screen-container{
    width: 100%;
    height: 750px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.mchat-result-screen-container .mchat-result-screen-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 80%;
    padding: 80px 20px;
}

.mchat-result-screen-container .mchat-result-screen-content p{
    font-size:20px;
    font-family:'CocoSharp regular';
    margin:0px
}

.mchat-result-screen-container .mchat-floated-circle{
    position:absolute;
}

.mchat-result-screen-container .mchat-floated-circle.circle-1{
    right: 0px;
    z-index:-1;
    bottom:-450px;
}

.mchat-result-screen-container .mchat-floated-circle.circle-2{
    left: 0px;
    z-index:-1;
    bottom:-300px;
}

.mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btns-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btn{
    width: 150px;
    height: 50px;
    background-color: transparent;
    border-radius: 8px;
    color: #F99B9B;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #F99B9B;
    font-family:'CocoSharp regular';
}

.mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btn:not(:last-child){
    margin-right: 30px;
}

.mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btn.selected{
    width: 150px;
    height: 50px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    align-self: center;
    border: none;
}

@media (max-width:991px){

    .mchat-result-screen-container .mchat-result-screen-content{
        padding: 50px 20px;
        width: 100%;
    }
    
    .mchat-result-screen-container .mchat-floated-circle{
        max-width:70%;
    }

    .mchat-result-screen-container .mchat-floated-circle.circle-1{
        right: -150px;
    }
}

@media (max-width:576px){


    .mchat-result-screen-container{
        height:100%;
    }

    .mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btns-container{
        flex-direction: column;
    }

    .mchat-result-screen-container .mchat-result-screen-content .mchat-result-start-btn:not(:last-child){
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .mchat-result-screen-container .mchat-floated-circle.circle-1{
        bottom: -200px;
    }

    .mchat-result-screen-container .mchat-floated-circle.circle-2{
        top: 0px;
    }
}