.Insurance-pay-Page-Container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 100px 0px;
  }
  
  .Insurance-pay-Page-Container .Insurance-pay-Page-Body {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Insurance-pay-Page-Container .Insurance-pay-Page-Body h1 {
    color: #096E82;
    font-family: "CocoSharp bold";
    font-weight: bolder;
    font-size: 48px;
  }
  
  .Insurance-pay-Page-Container .Insurance-pay-Page-Body hr {
    height: 4px;
    margin-top: 5px;
    width: 80px;
    background-color: #AEDFE5;
  }
  
  

  .Insurance-pay-Page-Container .Insurance-pay-Page-Body .Insurance-pay-btn {
    width: 100%;
    height: 50px;
    margin-top: 30px;
    background-color: #F99B9B;
    border-radius: 10px;
    color: white;
    align-self: center;
    border: none;
  }
  
  @media (max-width: 768px) {
    .Insurance-pay-Page-Container .Insurance-pay-Page-Body {
      width: 70%;
    }
  }
  
  @media (max-width: 425px) {
    .Insurance-pay-Page-Container .Insurance-pay-Page-Body {
      width: 95%;
    }
  }
  
  