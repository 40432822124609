.parent-dashboard-screener-intro{
    padding: 0px;
}

.parent-dashboard-screener-intro .parent-dashboard-screener-intro-body{
    padding: 50px;
}


@media (max-width:576px){
    .parent-dashboard-screener-intro .parent-dashboard-screener-intro-body{
        padding: 50px 20px;
    }
}