.parent-dashboard-instructions-and-guidence {
  padding: 0px;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body {
  padding: 40px;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  h1 {
  font-size: 36px;
  font-weight: 700;
  color: #096E82;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  .parent-dashboard-instructions-and-guidence-videos {
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  .parent-dashboard-instructions-and-guidence-videos
  .video-continer {
  width: 225px;
  height: 160px;
  margin: 20px 20px 0px 0px;
  border-radius: 8px;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  .parent-dashboard-instructions-and-guidence-videos
  .video-continer
  img {
  max-width: 100%;
  height: 160px;
  border-radius: 8px;
  cursor: pointer;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  .parent-dashboard-instructions-and-guidence-notes-section {
  display: flex;
  flex-wrap: wrap;
}

.parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body
  h2 {
  font-size: 28px;
  font-weight: 700;
  color: #096E82;
}

@media(max-width: 576px){
  .parent-dashboard-instructions-and-guidence
  .parent-dashboard-instructions-and-guidence-body {
    padding: 40px 10px;
  }
}

@media (max-width: 425px) {
  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body
    .parent-dashboard-instructions-and-guidence-videos {
    display: flex;
  }


  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body
    .parent-dashboard-instructions-and-guidence-notes-section {
    display: flex;
  }

  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body
    h1 {
    font-size: 32px;
  }

  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body
    h2 {
    font-size: 24px;
  }
}

/* @media (min-width: 1440px) {
  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body {
    padding: 20px 3vw;
  }
}

@media (min-width: 1920px) {
  .parent-dashboard-instructions-and-guidence
    .parent-dashboard-instructions-and-guidence-body {
    padding: 20px 5vw;
  }
} */
