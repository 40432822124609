.Package-card-container {
  width: 310px;
  min-height: 400px;
  border: solid 2px var(--secondary-color);
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}

.Package-card-container:not(:last-child){
  margin-right: 20px;
}

.Package-card-container .Package-card-header {
  padding: 6px 40px;
  border-radius: 8px 0px 20px 0px;
  display: inline-block;
  background-color: var(--secondary-color);
  margin-left: -1px;
  margin-top: -1px;
}

.Package-card-container .Package-card-header p {
  color: var(--text-color3);
  margin: 0px;
  font-size: 14px;
  float: left;
}

.Package-card-container .Package-card-header .Package-title {
  color: white;
}

.Package-card-container .Package-card-body {
  padding: 20px;
  min-height:280px;
  overflow: hidden;
  margin-bottom: 50px;
}

.Package-card-container .Package-card-body h1 {
  margin: 0px;
  text-align: left !important;
}

.Package-card-container .Package-card-body ul {
  padding: 10px 25px;
}

.Package-card-container .Package-card-body ul li {
  margin: 0px;
  font-family: "Montserrat-regular";
  font-size: 14px;
}

.Package-card-container .package-card-buy-btn{
  width: 200px;
  height: 40px;
  margin-top: 0px;
  background-color: #F99B9B;
  font-family: "CocoSharp regular";
  border-radius: 7px;
  color: white;
  border: none;
}

@media(max-width:1460px){
  .Package-card-container {
    width: 300px;
  }
  .Package-card-container .Package-card-header {
    width: 253px;
    padding: 5px 10px;
  }
}

@media(max-width:1280px){
  .Package-card-container {
    width: 280px;
  }

  .Package-card-container:not(:last-child){
    margin-right: 10px;
  }

  .Package-card-container .Package-card-header {
    width: 243px;
    padding: 5px 10px;
  }

  .Package-card-container .Package-card-header .Package-title {
    margin-right: 20px;
  }
}

@media(max-width:1200px){
  .Package-card-container {
    width: 260px;
  }

  

  .Package-card-container .Package-card-header {
    width: 223px;
    padding: 5px 10px;
  }


  .Package-card-container .Package-card-body {
    padding: 20px 10px 20px 10px;
  }

}

@media(max-width:1024px){
  .Package-card-container:not(:last-child){
    margin-right: 5px;
  }
}

@media(max-width:576px){
  .Package-card-container {
    width: 310px;
  }
}