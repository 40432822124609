.watch-video-container {
    padding: 0px;
  }
  
  .watch-video-container
    .watch-video-body {
    padding: 40px;
  }

  

  /* @media(min-width:1440px){
    .watch-video-container .watch-video-body{
      padding: 20px 3vw;
    }
  }
  
  @media(min-width:1920px){
    .watch-video-container .watch-video-body{
      padding: 20px 5vw;
    }
  } */
  