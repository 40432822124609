.clinician-edit-profile-container {
  padding: 0px;
}

.clinician-edit-profile-container .clinician-edit-profile-body {
  padding: 40px;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row {
  border-top: solid 1px rgba(196, 196, 196, 0.5);
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .clinician-edit-profile-body-row-col-1 {
  padding-right: 30px;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .clinician-edit-profile-body-row-col-2 {
  border-left: solid 1px rgba(196, 196, 196, 0.5);
  padding-left: 15px !important;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .edit-profile-btn {
  width: 100%;
  height: 40px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .connect-stripe-button{
  width: 100%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  color: #F99B9B;
  align-self: center;
  border: solid 2px #F99B9B;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#edit-profile-modal-btn {
  width: 100%;
  height: 40px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  border-radius: 20px;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .edit-profile-btn:hover {
  cursor: pointer;
}

.clinician-edit-profile-container
  .clinician-edit-profile-body
  .clinician-edit-profile-body-row
  .clinician-edit-profile-body-row-col-2
  .add-more-link {
  cursor: pointer;
}

@media (max-width: 991px) {

  .clinician-edit-profile-container .clinician-edit-profile-body {
    padding: 40px 10px;
  }

  .clinician-edit-profile-container
    .clinician-edit-profile-body
    .clinician-edit-profile-body-row {
    border-top: none;
  }
  .clinician-edit-profile-container
    .clinician-edit-profile-body
    .clinician-edit-profile-body-row
    .clinician-edit-profile-body-row-col-2 {
    border-left: none;
    padding-left: 0px !important;
    padding: 0px 40px !important;
  }
  .clinician-edit-profile-container
    .clinician-edit-profile-body
    .clinician-edit-profile-body-row
    .clinician-edit-profile-body-row-col-1 {
    padding-right: 0px;
    padding: 0px 50px;
  }
  .c-p-0 {
    padding: auto 0px !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 576px) {
  .clinician-edit-profile-container
    .clinician-edit-profile-body
    .clinician-edit-profile-body-row
    .clinician-edit-profile-body-row-col-2 {
    padding: 0px !important;
  }
  .clinician-edit-profile-container
    .clinician-edit-profile-body
    .clinician-edit-profile-body-row
    .clinician-edit-profile-body-row-col-1 {
    padding: 0px 15px;
  }
  .c-p-0 {
    padding: auto 0px !important;
  }
}

/* @media (min-width: 1440px) {
  .clinician-edit-profile-container .clinician-edit-profile-body {
    padding: 20px 3vw;
  }
}

@media (min-width: 1920px) {
  .clinician-edit-profile-container .clinician-edit-profile-body {
    padding: 20px 5vw;
  }
} */
