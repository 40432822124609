.dashboard-report-gen-container {
    padding: 0px;
  }
  
  .dashboard-report-gen-container
    .dashboard-report-gen-body {
    padding: 40px;
  }
  
  .dashboard-report-gen-container
    .dashboard-report-gen-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .dashboard-report-gen-container
    .dashboard-report-gen-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }

  .dashboard-report-gen-container
    .dashboard-report-gen-body
    .child-card-container {
      height:100px;
      width:100%;
      padding:0px 20px;
      margin-bottom:25px;
      margin-top:10px;
      display:flex;
      align-items:center;
      justify-content:space-between;
      border: solid 1px #F99B9B;
      border-radius: 10px;
      box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
  }

  .dashboard-report-gen-container
    .dashboard-report-gen-body
    .report-gen-req-btn {
      width: 150px;
      height: 40px;
      margin-top: 0px;
      background-color: white;
      border: solid 1px #F99B9B;
      font-family: "CocoSharp regular";
      border-radius: 7px;
      color: #F99B9B;
  }

  .request-session-detail-modal .modal-content{
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 20px !important; 
    border:none
  }

  #request-session-detail-modal-btn{
    width: 100%;
    height: 42px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 7px;
    color: white;
    align-self: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #start-timer-modal-btn{
    width: 45%;
    height: 42px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 7px;
    color: white;
    align-self: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .docs-gm .docs-butterbar-container{
    display: none !important;
  }

  @media(max-width: 576px){
    .dashboard-report-gen-container
    .dashboard-report-gen-body {
      padding: 40px 10px;
    }
  }
  
  @media (max-width: 425px) {
    .dashboard-report-gen-container
      .dashboard-report-gen-body
      h1 {
      font-size: 32px;
    }
  
    .dashboard-report-gen-container
      .dashboard-report-gen-body
      h2 {
      font-size: 24px;
    }
  }

  