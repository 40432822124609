.Forgot-Pass-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 120px 0px;
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body h1 {
  color: #096E82;
  font-weight: 600;
  margin: 0px;
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body hr {
  height: 4px;
  width: 80px;
  background-color: #F99B9B;
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body p {
  font-size: 14px;
  margin-top: 10px;
  font-family: 'CocoSharp regular';
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body .forgot-pass-btn {
  width: 100%;
  height: 50px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
}

.Forgot-Pass-Page-Container .Forgot-Pass-Page-Body .forgot-pass-page-link {
  color: #F99B9B;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  font-family: 'CocoSharp bold';
}

@media (max-width: 768px) {
  .Forgot-Pass-Page-Container .Forgot-Pass-Page-Body {
    width: 60%;
  }
}

@media (max-width: 425px) {
  .Forgot-Pass-Page-Container .Forgot-Pass-Page-Body {
    width: 95%;
  }

  .Forgot-Pass-Page-Container .Forgot-Pass-Page-Body h1 {
    font-size: 32px;
  }
}
