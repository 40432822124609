.bc-dashboard-sessions-container {
    padding: 0px;
  }
  
  .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body {
    padding: 40px;
  }
  
  .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }

  .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body
    .clinician-sessions-search-btn {
        width: 150px;
        height: 44px;
        margin-top: 0px;
        background-color: #F99B9B;
        border-radius: 7px;
        color: white;
        align-self: center;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table table
  {
    border: solid 2px #030533;
    border-radius: 15px;
    margin-top: 20px;
    width: 100%;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius: 15px;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table-container table > thead > tr:first-child th{
    background-color: #F2F0FF;
    font-family: 'CocoSharp bold';
    font-size: 20px;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table-container table > thead > tr:last-child th:last-child{
    border-top-right-radius: 15px;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table-tbody > tr > td{
    border-top: solid 1px #030533;
    border-bottom: 0px;
  }

  .bc-dashboard-sessions-container .bc-dashboard-sessions-body .clinician-sessions-table
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }
  
  @media(max-width: 576px){
    .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body {
      padding: 40px 10px;
    }

    .bc-dashboard-sessions-container
    .bc-dashboard-sessions-body
    .clinician-sessions-search-btn {
        margin-left: 0px;
    }
  }
  
  @media (max-width: 425px) {
    .bc-dashboard-sessions-container
      .bc-dashboard-sessions-body
      h1 {
      font-size: 32px;
    }
  
    .bc-dashboard-sessions-container
      .bc-dashboard-sessions-body
      h2 {
      font-size: 24px;
    }
  }

  