.assigned-bcba-page-container{
    padding: 0px;
}


.assigned-bcba-page-container .assigned-bcba-page-body {
  padding: 40px;
}

.assigned-bcba-page-container .assigned-bcba-page-body .request-bcba-btn{
  width: 150px;
  height: 35px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:576px){
  .assigned-bcba-page-container .assigned-bcba-page-body {
    padding: 40px 10px;
  }
}