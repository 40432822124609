.diagnosis-reports-page-container{
    padding: 0px;
}


.diagnosis-reports-page-container .diagnosis-reports-page-body {
  padding: 40px;
}

.diagnosis-reports-page-container .diagnosis-reports-page-body .diagnosis-reports-create-report-btn{
  width: 150px;
  height: 38px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  padding: 0px 15px;
  color: white;
  align-self: end;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.diagnosis-reports-page-create-modal .modal-content{
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important; 
  border:none
}

#diagnosis-reports-page-modal-btn{
  width: 100%;
  height: 50px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 7px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:576px){
  .diagnosis-reports-page-container .diagnosis-reports-page-body {
    padding: 40px 10px;
  }
  
}