.upload_component_container {
    padding: 10px 0px;
    border: dashed 2px #030533;
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  
  .upload_component_container:hover {
    cursor: pointer;
  }
  
  .upload_component_container h5{
    color: #030533;
  }