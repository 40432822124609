.parent-dashboard-screener-result{
    padding: 0px;
}

.parent-dashboard-screener-result .parent-dashboard-screener-result-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px;
}


.parent-dashboard-screener-result .parent-dashboard-screener-result-content p{
    font-size:20px;
    font-family:'CocoSharp regular';
    margin:0px
}

.parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btns-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btn{
    width: 150px;
    height: 50px;
    background-color: transparent;
    border-radius: 8px;
    color: #F99B9B;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #F99B9B;
}

.parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btn:not(:last-child){
    margin-right: 30px;
}

.parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btn.selected{
    width: 150px;
    height: 50px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    align-self: center;
    border: none;
}

@media (max-width:991px){

    .parent-dashboard-screener-result .parent-dashboard-screener-result-content{
        padding: 50px 20px;
    }
    
}

@media (max-width:576px){

    .parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btns-container{
        flex-direction: column;
    }

    .parent-dashboard-screener-result .parent-dashboard-screener-result-content .parent-dashboard-screener-result-start-btn:not(:last-child){
        margin-right: 0px;
        margin-bottom: 20px;
    }

}
