.top-bar-container {
  width: 100%;
  padding: 30px 25px 0px 25px;
  display: flex;
  justify-content: flex-end;
  background-color: white;
}

.top-bar-container ul {
  display: flex;
  align-items: center;
  padding: 0px;
}

.top-bar-container ul li {
  list-style: none;
  margin-left: 15px;
  font-weight: 500;
  color: var(--text-color);
  position: relative;
  font-size: 16px;
}

.top-bar-container ul .li:hover {
  cursor: pointer;
}

.top-bar-container ul .li .topbar-link {
  color: black;
}

.top-bar-container ul .li .topbar-link:hover {
  text-decoration: none;
}

.top-bar-container ul .li .top-bar-button {
  width: 180px;
  height: 42px;
  background-color: white;
  border: solid 2px var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
}

@media (max-width: 1024px) {
  .top-bar-container {
    width: 100%;
    padding: 30px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .top-bar-container ul li {
    margin-left: 10px;
  }

  .top-bar-container ul .li .top-bar-button {
    width: 160px;
    height: 40px;
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .top-bar-container ul .li .top-bar-button {
    width: 150px;
    height: 40px;
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .top-bar-container ul .li .dashboard-text {
    display: none;
  }
}
