.rating-form-fld-container{
    width: 100%;
    margin-top: 50px;
}

.rating-form-fld-container .rating-form-fld-title{
    font-size: 24px;
    margin: 0px;
    margin-bottom: 10px;
}

.rating-form-fld-container .rating-form-fld-col{
    font-size: 14px !important;
    border: solid 1px var(--text-color);
    margin: 0px;
}

.rating-form-fld-container .rating-form-fld-col:nth-child(1){
    border-right: none;
    border-radius: 8px 0px 0px 8px;
}

.rating-form-fld-container .rating-form-fld-col:nth-child(3){
    border-left: none;
    border-radius: 0px 8px 8px 0px;
}

.rating-form-fld-container .rating-form-fld-col-4,.rating-form-fld-container .rating-form-fld-col-5{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rating-form-fld-container .rating-form-fld-col-4 input{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    text-align: center;
    border: solid 1px var(--text-color);
    font-size: 24px;
}
.rating-form-fld-container .rating-form-fld-col-4 p{
    font-size: 12px !important;
    text-align: center;
    margin: 0px;
}

.rating-form-fld-container .rating-form-fld-col-5 input{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    text-align: center;
    border: solid 1px var(--text-color);
    font-size: 24px;
}

.rating-form-fld-container .rating-form-fld-col-5 p{
    font-size: 12px !important;
    text-align: center;
    margin: 0px;
}

/* Hiding arrows */
.rating-form-fld-container .rating-form-fld-col-4 input::-webkit-outer-spin-button,
.rating-form-fld-container .rating-form-fld-col-4 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.rating-form-fld-container .rating-form-fld-col-4 input[type=number] {
  -moz-appearance: textfield;
}

.rating-form-fld-container .rating-form-fld-col-5 input::-webkit-outer-spin-button,
.rating-form-fld-container .rating-form-fld-col-5 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.rating-form-fld-container .rating-form-fld-col-5 input[type=number] {
  -moz-appearance: textfield;
}