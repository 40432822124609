.checkbox-component-container .component-checkbox{
    width:25px;
    min-width: 25px;
    height:25px;
    border:solid 3px black;
    border-radius:5px 0px 5px 5px;
    border-right: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox-component-container .component-checkbox::after{
    content:'';
    position: absolute;
    width: 3px;
    background-color: black;
    right: 0px;
    bottom: 0px;
    height: 35%;
}


.checkbox-component-container .component-checkbox.checked{
    border:solid 3px var(--secondary-color);
    width:25px;
    height:25px;
    border-radius:5px 0px 5px 5px;
    border-right: none;
    position: relative;
}


.checkbox-component-container .component-checkbox.checked::after{
    content:'';
    position: absolute;
    width: 3px;
    background-color: var(--secondary-color);
    right: 0px;
    bottom: 0px;
    height: 35%;
}

.checkbox-component-container .component-checkbox:hover{
    cursor: pointer;
}