.video-comp-container {
  width: 100%;
}

#player-icon-linked:hover {
  cursor: pointer;
}

.video-comp-container .video-controls-container {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px lightgray;
}

@media (max-width: 425px) {
  .video-comp-container .video-controls-container {
    padding: 0px 0px;
  }
}
