.child-condition-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 20px;
}

.child-condition-main-container h1 {
    font-family: 'CocoSharp bold';
    color: #096E82;
    font-size: 38px;
    margin: 5px 0px;
}
  
.child-condition-main-container hr {
    border-bottom: solid 5px #F99B9B;
    width: 100px;
    margin-top: 0px;
}

.child-condition-main-container .child-condition-cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-1{
    margin-right: 60px;
    width: 450px;
    position: relative;
    cursor: pointer;
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-1 p{
    font-family: 'CocoSharp regular';
    font-size: 20px;
    color: white;
    margin: 0px;
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-1 h3{
    font-size: 34px;
    color: white;
    margin: 0px;
    font-family: 'CocoSharp bold';
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-2{
    width: 450px;
    position: relative;
    cursor: pointer;
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-2 p{
    font-size: 20px;
    color: white;
    margin: 0px;
    font-family: 'CocoSharp regular';
}

.child-condition-main-container .child-condition-cards-container .child-condition-card-2 h3{
    font-size: 34px;
    color: white;
    margin: 0px;
    font-family: 'CocoSharp bold';
}

@media(max-width:1024px){
    .child-condition-main-container .child-condition-cards-container .child-condition-card-1{
        width: 400px;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-2{
        width: 400px;
    }
}

@media(max-width:991px){
    .child-condition-main-container .child-condition-cards-container .child-condition-card-1{
        width: 350px;
        margin-right: 30px;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-2{
        width: 350px;
    }
}

@media(max-width:576px){

    .child-condition-main-container .child-condition-cards-container{
        flex-direction: column;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-1{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-1 p{
        font-size: 16px;
    }
    
    .child-condition-main-container .child-condition-cards-container .child-condition-card-1 h3{
        font-size: 24px;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-2{
        width: 100%;
    }

    .child-condition-main-container .child-condition-cards-container .child-condition-card-2 p{
        font-size: 16px;
    }
    
    .child-condition-main-container .child-condition-cards-container .child-condition-card-2 h3{
        font-size: 24px;
    }
}