.chat-messages-container{
    border: solid 1px #096E82;
    width: 73%;
    border-radius: 15px;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.chat-messages-container .chat-messages-header{
    padding: 0px 20px;
    border-bottom: solid 1px #096E82;
    height: 12%;
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.chat-messages-container .chat-messages-header #chat-contact-list-bar{
    display: none;
}

.chat-messages-container .chat-messages-body{
    height: 76%;
    padding: 20px;
    overflow-y: auto;
}

.chat-messages-container .chat-messages-body::-webkit-scrollbar {
    width: 3px;
}
  
  /* Track */
.chat-messages-container .chat-messages-body::-webkit-scrollbar-track {
    background: transparent;
}
  
  /* Handle */
.chat-messages-container .chat-messages-body::-webkit-scrollbar-thumb {
    background: #096E82;
}

.chat-messages-container .chat-messages-footer{
    padding: 0px 20px;
    height: 12%;
    display: flex;
    align-items:center;
}

@media (max-width:1280px){
    .chat-messages-container{
        width: 71%;
    }
}

@media (max-width:768px){
    .chat-messages-container .chat-messages-header #chat-contact-list-bar{
        display: block;
    }
    .chat-messages-container{
        width: 100%;
    }
}

@media (max-width:576px){

    .chat-messages-container .chat-messages-header{
        padding: 0px 10px;
    }

    .chat-messages-container .chat-messages-body{
        padding: 20px 10px;
    }

    .chat-messages-container .chat-messages-footer{
        padding: 0px 10px;
    }
}