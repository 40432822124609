.dashboard-parent-card-container {
    padding: 15px 10px;
    border: solid 1px gray;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    width: 215px;
    height: 205px;
    margin-top: 20px;
    margin-right: 30px;
  }