.fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set .legend{
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: black;
}


.fld_set .input, .fld_set_err .input, .fld_set_fcs .input  {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
}

.fld_set .input:focus, .fld_set_err .input:focus, .fld_set_fcs .input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}


.form-control:disabled{
  background-color: transparent !important;
}

/* on Focus */
.fld_set_fcs{
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_fcs .legend1  {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
}

/* error */
.fld_set_err{
  width: 100%;
  border: solid 2px red;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_err .legend2{
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: red !important;
}

.date-picker-class{
  width: 100%;
  border: none;
}

.react-date-picker__calendar{
  z-index: 1000;
}

.react-date-picker__calendar .react-calendar{
  width: 280px;
}

.date-picker-class .react-date-picker__wrapper{
  border: none;
  padding: .275rem .1rem .575rem .5rem ;
}

.fld_set_fcs .react-calendar__tile--now{
  background-color: transparent;
}

.fld_set_fcs .react-calendar__tile:hover, .fld_set_fcs .react-calendar__tile--now:hover{
  background-color: #e5e2fa;
}

.fld_set_fcs .react-calendar__tile--active, .fld_set_fcs .react-calendar__tile--hasActive{
  background-color: #F99B9B !important;
  color: white ;
}

.fld_set_fcs .react-calendar__navigation button[disabled], .fld_set_fcs .react-calendar__tile:disabled{
  background-color: white;
}


.fld_set_fcs .react-calendar__tile:disabled abbr{
  color: lightgray;
}

.fld_set_fcs .react-calendar__navigation button:hover{
  background-color: #e5e2fa;
} 


/* For Error */

.fld_set_err .react-calendar__tile--now{
  background-color: transparent;
}

.fld_set_err .react-calendar__tile:hover, .fld_set_err .react-calendar__tile--now:hover{
  background-color: #e5e2fa;
}

.fld_set_err .react-calendar__tile--active, .fld_set_err .react-calendar__tile--hasActive{
  background-color: #F99B9B !important;
  color: white ;
}

.fld_set_err .react-calendar__navigation button[disabled], .fld_set_err .react-calendar__tile:disabled{
  background-color: white;
}


.fld_set_err .react-calendar__tile:disabled abbr{
  color: lightgray;
}

/* .fld_set_err .react-calendar__tile, .fld_set_err .react-calendar__month-view__weekdays__weekday{
  max-width:45px !important;
}*/

.fld_set_err .react-calendar__navigation button:hover{
  background-color: #e5e2fa;
} 

/* input style */
.react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month{
  width: 18px !important;
}

.react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day.react-date-picker__inputGroup__input--hasLeadingZero{
  width: 10px !important;
}

.react-date-picker__inputGroup .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year{
  width: 35px !important;
}