.parent-edit-profile-page-container{
    padding: 0px;
}


.parent-edit-profile-page-container .parent-edit-profile-page-body {
  padding: 40px;
}

.parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row{
  border-top: solid 1px rgba(196, 196, 196, 0.5);
}

.parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-1{
  padding-right: 20px;
  text-align: center;
}

.parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-2{
  border-left:solid 1px rgba(196, 196, 196, 0.5);
  padding-left: 15px !important;
}

.parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .edit-profile-btn{
  width: 100%;
  height: 40px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}


.parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .edit-profile-btn:hover{
  cursor: pointer;
}


@media(max-width:991px){
  .parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row{
    border-top: none;
  }
  .parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-2{
    border-left:none;
    padding-left: 0px !important;
    padding:0px 40px !important;
  }
  .parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-1{
    padding-right: 0px;
    padding:0px 50px;
  }
}

@media(max-width:576px){
  .parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-2{
    padding:0px!important;
  }
  .parent-edit-profile-page-container .parent-edit-profile-page-body .parent-edit-profile-page-body-row .parent-edit-profile-page-body-row-col-1{
    padding: 0px 15px;
  }
}


@media(max-width:576px){
  .parent-edit-profile-page-container .parent-edit-profile-page-body {
    padding: 40px 10px;
  }
}
/* @media(min-width:1440px){
  .parent-edit-profile-page-container .parent-edit-profile-page-body{
    padding: 20px 3vw;
  }
}

@media(min-width:1920px){
  .parent-edit-profile-page-container .parent-edit-profile-page-body{
    padding: 20px 5vw;
  }
} */
