.parent-dashboard-home {
  padding: 0px;
}

.parent-dashboard-home .parent-dashboard-home-body {
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media (max-width:576px){
  .parent-dashboard-home .parent-dashboard-home-body {
    padding: 40px 10px;
  }
}

/* @media(min-width:1440px){
  .parent-dashboard-home .parent-dashboard-home-body{
    padding: 20px 3vw;
  }
}*/

@media(min-width:2100px){
  .parent-dashboard-home .parent-dashboard-home-body {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: flex-start; */
  }
} 

