.second-user-bubble-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
}

.second-user-bubble-container .second-user-bubule{
    display: flex;
    align-items: flex-start;
}