.tc-inner-container{
    padding-top: 140px !important;
    padding-bottom: 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.tc-heading{
    font-family: 'CocoSharp bold';
    color: #096E82;
    text-align: center;
    font-size: 54px;
    margin-bottom: 0px;
}

.tc-divider{
    border-bottom: solid 5px #F99B9B;
    width: 250px;
    margin-top: 10px !important;
    margin: auto;
    margin-inline-start: auto !important;
    margin-inline-end: auto !important;
}

.tc-inner-container .tc-content{
    width: 60%;
    margin-top: 80px;
    font-size: 18px;
}

.ellipse-bg{
    position: absolute;
    z-index: -5;
    left: -10% !important;
    top: 50%;
    transform: translateY(-50%);
    max-height: 50% !important;
}


@media(max-width:1440px){
    .tc-inner-container .tc-content{
        width: 80%;
    } 
}

@media(max-width:1280px){
    .tc-heading{
        font-size: 54px;
    }
}

@media(max-width:768px){
    .tc-inner-container .tc-content{
        width: 90%;
        font-size: 16px;
    } 
}

@media(max-width:576px){
    .tc-inner-container .tc-content{
        width: 100%;
    } 
    
    .ellipse-bg{
        display: none !important;
     }
}