.parent-dashboard-set-availability-container {
    padding: 0px;
  }
  
  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body {
    padding: 40px;
  }
  
  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-confirm-btn {
      width: 200px;
      height: 50px;
      margin-top: 50px;
      background-color: #096E82;
      border-radius: 8px;
      color: white;
      border: none;
  }

  .parent-dashboard-set-availability-container
  .parent-dashboard-set-availability-body
  .parent-dashboard-set-availability-clocks-container{
      display:flex;
      align-items:flex-start;
      margin-top:50px
  }

  .parent-dashboard-set-availability-container
  .parent-dashboard-set-availability-body
  .parent-dashboard-set-availability-clocks-container > div{
    margin-right: 50px;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock {
      width: 500px;
      border: solid 1px #096E82;
      border-radius: 20px;
  }

  .parent-dashboard-set-availability-container
  .parent-dashboard-set-availability-body
  .parent-dashboard-set-availability-clock.disabled{
    pointer-events: none !important;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock.disabled .css-1umqo6f{
     pointer-events: none !important;
  }

  /* .parent-dashboard-set-availability-container
  .parent-dashboard-set-availability-body
  .parent-dashboard-set-availability-clock .to-clock-class {
    pointer-events: none !important;
  } */

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock .MuiPickerStaticWrapper-root {
      border-radius: 20px;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock .MuiPickerStaticWrapper-root .css-xelq0e-MuiPickerStaticWrapper-content{
      border-radius: 20px;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock .css-fhpqww {
      justify-content: flex-end !important;
  }

  .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock .css-zylse7-MuiButtonBase-root-MuiIconButton-root{
    display: none !important;
  }

  @media (max-width:1288px){
    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock {
      width: 450px;
    }

    /* .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock:first-child {
      margin-right: 20px;
    } */

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clocks-container > div{
      margin-right: 20px;
    }
  }
  

  @media (max-width:1024px){
    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clocks-container{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock {
      width: 500px;
    }

    /* .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock:first-child {
      margin-right: 0px;
      margin-bottom: 30px;
    } */

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clocks-container > div{
      margin-right: 0px;
      margin-bottom: 30px;
    }
  }

  @media(max-width: 576px){
    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body {
      padding: 40px 10px;
    }

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock {
      width: 400px;
    }
  }
  
  @media (max-width: 425px) {
    .parent-dashboard-set-availability-container
      .parent-dashboard-set-availability-body
      h1 {
      font-size: 32px;
    }
  
    .parent-dashboard-set-availability-container
      .parent-dashboard-set-availability-body
      h2 {
      font-size: 24px;
    }

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock {
      width: 325px;
    }

    .parent-dashboard-set-availability-container
    .parent-dashboard-set-availability-body
    .parent-dashboard-set-availability-clock .css-fhpqww {
      justify-content: center !important;
  }
  }

  