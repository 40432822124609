.calendar-componete-time-accordian {
  padding-top: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 48px;
  overflow: hidden;
}

.calendar-componete-time-accordian .time-btn,
.calendar-componete-time-accordian-full .time-btn {
  width: calc(100% / 4.5);
  padding: 7px 5px;
  border: solid 1px #F99B9B;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
  box-shadow: none;
}

.calendar-componete-time-accordian .time-btn:hover,
.calendar-componete-time-accordian .time-btn:focus,
.calendar-componete-time-accordian-full .time-btn:hover,
.calendar-componete-time-accordian-full .time-btn:focus {
  outline: none;
  box-shadow: none;
  background-color: #F99B9B;
  color: white;
  border: solid 1px #F99B9B;
}

/* Complete time Slotes */
.calendar-componete-time-accordian-full {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 10px;
}

.calendar-component .down-arrow-icon-calendar:hover {
  cursor: pointer;
}

/* CSS override */
.calendar-component .react-calendar {
  border: none;
  width: 100%;
}


.calendar-component .react-calendar__tile--now,
.calendar-component .react-calendar__tile--now:hover {
  background-color: #f7f7f7 !important;
  color: black !important;
}

.calendar-component .react-calendar__tile--now:focus,
.calendar-component .react-calendar__tile--now.react-calendar__tile--active{
  background: #F99B9B !important;
  color: white !important;
}

.calendar-component .react-calendar__tile--active:enabled:hover,
.calendar-component .react-calendar__tile--active:enabled:focus,
.calendar-component .react-calendar__tile--active {
  /* background: #00C8D4;
  background-size: 50% auto; */
  /* background:  linear-gradient(360deg, white 20%,240deg, white 20%, #00FFFF 20%), */
  background-color: #F99B9B;
  /* -webkit-box-shadow: 6px 0 0px 10px white inset, -6px 0 0px 10px white inset;
-moz-box-shadow: 1em 0 0px 10px white inset, -1em 0 0px 10px white inset;
box-shadow: 15px 0px 0px 10px white inset, -15px 0px 0px 10px white inset; */
}

.calendar-component .react-calendar__tile:disabled,
.calendar-component .react-calendar__navigation button[disabled] {
  background-color: white;
  color: lightgray;
}

.calendar-component .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from{
  font-weight: bold !important;
}

@media (max-width: 1024px) {
  .calendar-componete-time-accordian {
    height: 65px;
  }
}


@keyframes opacityAnimation {
  0%{
    opacity:48px;
  }
  100%{
    opacity:100%;
  }
}