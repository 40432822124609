.child-profile-page-container{
    padding: 0px;
}


.child-profile-page-container .child-profile-page-body {
  padding: 40px;
}


.child-profile-page-container .child-profile-page-body .child-profile-add-child-btn{
  width: 150px;
  height: 38px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 10px;
  padding: 0px 15px;
  color: white;
  align-self: end;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.child-profile-page-container .child-profile-page-body .child-profile-page-tab-bar{
  width:100%;
  display:flex;
  align-items:flex-start;
  margin-top: 30px;
}


.child-profile-page-container .child-profile-page-body .child-profile-page-tab-bar .child-profile-page-tab-bar-btn{
  border:solid 2px var(--primary-color);
  font-family:'CocoSharp regular';
  background-color:transparent;
  font-size:18px;
  padding: 10px 25px;
  border-radius: 8px;
}

.child-profile-page-container .child-profile-page-body .child-profile-page-tab-bar .child-profile-page-tab-bar-btn:not(:last-child){
  margin-right: 10px;
}

@media(max-width:576px){
  .child-profile-page-container .child-profile-page-body {
    padding: 40px 10px;
  }
  .child-profile-page-container .child-profile-page-body .child-profile-page-tab-bar .child-profile-page-tab-bar-btn{
    font-size:16px;
    padding: 10px 15px;
  }
}

@media(max-width:320px){

  .child-profile-page-container .child-profile-page-body .child-profile-page-tab-bar .child-profile-page-tab-bar-btn{
    font-size:14px;
    padding: 10px 15px;
  }
}

/* @media(min-width:1440px){
    .child-profile-page-container .child-profile-page-body{
      padding: 20px 3vw;
    }
  }
  
  @media(min-width:1920px){
    .child-profile-page-container .child-profile-page-body{
      padding: 20px 5vw;
    }
  } */