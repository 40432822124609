.Payment-type-Page-Container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 120px 0px;
  }
  
  .Payment-type-Page-Container .Payment-type-Page-Body {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Payment-type-Page-Container .Payment-type-Page-Body h1 {
    color: #096E82;
    font-family: 'CocoSharp bold';
    font-weight: bolder;
    font-size: 48px;
    margin-bottom: 0px;
  }
  
  .Payment-type-Page-Container .Payment-type-Page-Body hr {
    height: 4px;
    margin-top: 5px;
    width: 80px;
    background-color: #F99B9B;
    margin-bottom: 40px;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row{
      width: 50%;
      display: flex;
      align-items:center;
      justify-content: space-between;
      margin-top: 50px;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card{
    width: 300px;
    height: 180px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px #F99B9B;
    cursor: pointer;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card img{
      color:#F99B9B;
      width: 60px;
      height: 60px;
      -webkit-filter: invert(73%) sepia(73%) saturate(492%) hue-rotate(304deg) brightness(106%) contrast(96%);
      filter: invert(73%) sepia(73%) saturate(492%) hue-rotate(304deg) brightness(106%) contrast(96%);
  }
  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card h3{
      color:#F99B9B;
      margin-top: 20px;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card.active{
      background-color:#F99B9B;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card.active img{
    -webkit-filter:brightness(0) invert(1);
    filter:brightness(0) invert(1);
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card.active h3{
      color:white;
  }

  .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-cont-btn{
    width: 300px;
    height: 50px;
    margin-top: 0px;
    background-color: #096E82;
    border-radius: 10px;
    color: white;
    align-self: center;
    border: none;
    margin-top: 80px;
  }
  
  
  @media (max-width: 1550px) {
    .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row{
        width: 60%;
    }
  }
  
  @media (max-width: 1280px) {
    .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row{
        width: 75%;
    }
  }

  @media (max-width: 1024px) {
    .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row{
        width: 100%;
    }
  }

  @media (max-width: 768px) {
    .Payment-type-Page-Container .Payment-type-Page-Body {
        width: 100%;
      }
  }
  
  @media (max-width: 625px) {
    .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row{
        flex-direction: column;
    }

    .Payment-type-Page-Container .Payment-type-Page-Body .Payment-type-select-row .Payment-type-card:nth-child(2){
        margin-top: 25px;
    }
  }
  