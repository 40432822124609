.order-detail-comp-container {
    width: 100%;
}


.order-detail-comp-container
  .order-detail-comp-Sec-1
  h3 {
  color: #F99B9B;
  font-weight: 700;
  font-size: 30px;
}

.order-detail-comp-container
  .order-detail-comp-Sec-1
  h4 {
  font-weight: 400;
  font-size: 25px;
}

.order-detail-comp-container .order-detail-comp-Sec-1 .order-detail-comp-btns-container{
    display: flex;
    align-items: center;
}

.order-detail-comp-container
.order-detail-comp-Sec-1 .order-detail-comp-btns-container
  .order-detail-comp-btn {
  width: 200px;
  height: 50px;
  margin-top: 20px;
  border: none;
  background-color: #F99B9B;
  border-radius: 8px;
  color: white;
  margin-right: 20px;
}

.order-detail-comp-container 
.order-detail-comp-Sec-1 .order-detail-comp-btns-container
.order-detail-comp-btn-2{
    width: 200px;
    height: 50px;
    margin-top: 20px;
    border: solid 2px #F99B9B;
    background-color: transparent;
    border-radius: 8px;
    color: #F99B9B;
}

.order-detail-comp-container
  .order-detail-comp-Sec-1
  .order-detail-comp-btn:last-child {
  margin-left: 20px;
}

.order-detail-comp-container
  .order-detail-comp-Sec-2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.order-detail-comp-container
  .order-detail-comp-Sec-2
  .Payment-detail {
  width: 70%;
  padding: 20px;
  background-color: #f5f5f7;
  border-radius: 10px;
}

.order-detail-comp-container
  .order-detail-comp-Sec-2
  .Payment-detail
  h6 {
  font-size: 15px;
  font-weight: 600;
}

.order-detail-comp-container
  .order-detail-comp-Sec-2
  .Payment-detail
  p {
  margin: 0px;
  font-size: 15px;
}

.order-detail-comp-container
  .order-detail-comp-Sec-2
  .Payment-detail
  hr {
  height: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 50px;
}

@media (max-width: 1024px) {
  .order-detail-comp-container {
    width: 90%;
  }

 .order-detail-comp-container
    .order-detail-comp-Sec-2
    .Payment-detail {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .order-detail-comp-container {
    width: 95%;
  }

 .order-detail-comp-container
    .order-detail-comp-Sec-2
    .Payment-detail {
    width: 100%;
  }
}

@media (max-width: 425px) {
 .order-detail-comp-container
    .order-detail-comp-Sec-1 {
    margin-top: 40px;
  }

 .order-detail-comp-container
 .order-detail-comp-Sec-1 .order-detail-comp-btns-container
    .order-detail-comp-btn {
    width: 50%;
  }

}