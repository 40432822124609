.Payment-Card-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 120px 0px;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body h1 {
  color: #096E82;
  font-family: "CocoSharp bold";
  font-weight: bolder;
  font-size: 48px;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body hr {
  height: 4px;
  margin-top: 5px;
  width: 80px;
  background-color: #AEDFE5;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container {
  margin-top: 25px;
  width: 320px;
  height: 65px;
  border-radius: 20px;
  background-color: rgba(216, 219, 221, 0.3);
  display: flex;
  position: relative;
  overflow: hidden;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container.default::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  background-color: #F99B9B;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container.left::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 50%;
  background-color: #F99B9B;
  animation: leftAnimation 0.5s;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container.right::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50%;
  bottom: 0px;
  border-radius: 20px;
  background-color: #F99B9B;
  animation: rightAnimation 0.5s;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container
  .tabs {
  width: 160px;
  color: black;
  text-align: center;
  background-color: transparent;
  border: none;
  z-index: 100;
  animation: defaultColor 0.5s;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container
  .active-tab {
  color: white;
  animation: activeColor 0.5s;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .Payment-Card-tabs-container
  .active-default {
  width: 160px;
  color: white;
  text-align: center;
  background-color: transparent;
  border: none;
  z-index: 100;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body .Payment-Card-row {
  width: 100%;
  display: flex;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body .custom-check-box {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  margin-bottom: 0px;
  border: solid 2px black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  .custom-check-box
  .check-mark {
  width: 15px;
  height: 12px;
  border-radius: 50%;
  background-color: #F99B9B;
  /* box-shadow: 0px 0px 0px 2px #F99B9B; */
  border: solid 2px #F99B9B;
  display: none;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body input[type="checkbox"] {
  display: none;
}

.Payment-Card-Page-Container
  .Payment-Card-Page-Body
  input[type="checkbox"]:checked
  + .custom-check-box
  .check-mark {
  display: inline-block;
}

.Payment-Card-Page-Container .Payment-Card-Page-Body .Payment-Card-btn {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background-color: #F99B9B;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
}

@media (max-width: 768px) {
  .Payment-Card-Page-Container .Payment-Card-Page-Body {
    width: 70%;
  }
}

@media (max-width: 425px) {
  .Payment-Card-Page-Container .Payment-Card-Page-Body {
    width: 95%;
  }

  .Payment-Card-Page-Container .Payment-Card-Page-Body .Payment-Card-row {
    display: flex;
    flex-direction: column;
  }
}

/* Animations */
@keyframes leftAnimation {
  0% {
    left: 50%;
    width: 50%;
  }
  40% {
    width: 100%;
  }
  100% {
    right: 0%;
    width: 50%;
  }
}

@keyframes rightAnimation {
  0% {
    right: 50%;
    width: 50%;
  }
  40% {
    width: 100%;
  }
  100% {
    right: 0%;
    width: 50%;
  }
}

@keyframes defaultColor {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}

@keyframes activeColor {
  0% {
    color: black;
  }
  100% {
    color: white;
  }
}
