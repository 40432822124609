.reports-card-container{
    width: 220px;
    height: 215px;
    border-radius: 10px;
    border:solid 1px var(--text-color);
    padding: 15px;
    margin-right: 25px;
    margin-top: 25px;
}

.reports-card-container .reports-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}