.clinician-video-calling-page-container {
    width: 100%;
}

.clinician-video-calling-page-container .clinician-video-calling-page-body {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-1 {
  width: 50%;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 {
  width: 50%;
  height: 100%;
  padding: 0px 0px 0px 20px;
  margin-top: 65px;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 p{
  font-size: 18px;
  margin: 0px;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 h1 {
  color: #096E82;
  margin: 0px;
  font-size: 34px;
  font-family: 'CocoSharp bold';
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 hr {
  height: 4px;
  width: 85px;
  background-color: #F99B9B;
  margin-top: 0px;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .assesment-tool-save-btn{
  width: 140px;
  height: 50px;
  margin-top: 0px;
  background-color: #096E82;
  border-radius: 8px;
  color: white;
  align-self: center;
  border: none;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .clinician-video-calling-page-row-2-form-body {
  border: solid 3px #096E82;
  border-radius: 8px;
  height: 590px;
  overflow: auto;
  overflow-x: hidden;
  padding: 15px;
  position: relative;
  box-sizing: border-box;
  scrollbar-width: none;
}


.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .clinician-video-calling-page-row-2-form-body::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .clinician-video-calling-page-row-2-form-body::-webkit-scrollbar-track {
  background: #E8E9FF;
  border-radius: 0px 8px 8px 0px;
  padding: 0px 4px;
}

/* Handle */
.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .clinician-video-calling-page-row-2-form-body::-webkit-scrollbar-thumb {
  background: #096E82;
  border-radius: 8px;
  width: 4px;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 .clinician-video-calling-page-row-2-form-body .assesment-tool-btns-container {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  margin-top: 50px;
}


.clinician-video-calling-page-container .clinician-video-calling-page-row-2 
.clinician-video-calling-page-row-2-form-body .assesment-tool-btns-container .assesment-tool-next-btn {
  width: 140px;
  height: 50px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 8px;
  color: white;
  align-self: center;
  border: none;
}

.clinician-video-calling-page-container .clinician-video-calling-page-row-2 
.clinician-video-calling-page-row-2-form-body .assesment-tool-btns-container .assesment-tool-prev-btn {
  width: 140px;
  height: 50px;
  margin-top: 0px;
  background-color:white;
  border-radius: 8px;
  color: #F99B9B;
  align-self: center;
  border: solid 2px #F99B9B;
  margin-right: 40px;
}

@media(max-width:991px){
  .clinician-video-calling-page-container .clinician-video-calling-page-row-1 {
    width: 100%;
  }
  .clinician-video-calling-page-container .clinician-video-calling-page-row-2 {
    display: none;
  }
}

@media(max-width:576px){
  .clinician-video-calling-page-container .clinician-video-calling-page-body {
    padding: 40px 10px;
  }

  .clinician-video-calling-page-container .clinician-video-calling-page-row-1 {
    display: none;
  }
  .clinician-video-calling-page-container .clinician-video-calling-page-row-2 {
    display: block;
    width: 100%;
    margin-top: 0px;
  }
}



@keyframes leftAnimation {
  0%{
    left: 50%;
    width: 50%;
  }
  40%{
    width: 100%;
  }
  100%{
    right: 0%;
    width: 50%;
  }
}

@keyframes rightAnimation {
  0%{
    right: 50%;
    width: 50%;
  }
  40%{
    width: 100%;
  }
  100%{
    right: 0%;
    width: 50%;
  }
}

@keyframes defaultColor {
  0%{
    color: white;
  }
  100%{
    color: black;
  }
}

@keyframes activeColor {
  0%{
    color: black;
  }
  100%{
    color: white;
  }
}