.child-or-adult-body-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 20px;
}

.child-or-adult-body-content h1 {
    color: #0b0f66;
    font-size: 38px;
    font-weight: 700;
    margin: 5px 0px;
}
  
.child-or-adult-body-content hr {
    border-bottom: solid 5px var(--primary-color);
    width: 100px;
    margin-top: 0px;
}

.child-or-adult-body-content .child-or-adult-cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-1{
    margin-right: 60px;
    height: 378px;
    width: 378px;
    position: relative;
    cursor: pointer;
}

.child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-1 p{
    font-size:34px;
    color:white;
    position:absolute;
    bottom:10px;
    left:30px
}

.child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-2{
    position: relative;
    cursor: pointer;
    height: 378px;
    width: 378px;
}

.child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-2 p{
    font-size:34px;
    color:white;
    position:absolute;
    bottom:10px;
    left:30px
}

@media(max-width:576px){
    .child-or-adult-body-content .child-or-adult-cards-container{
        flex-direction: column;
    }

    .child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-1{
        margin-right: 0px;
        margin-bottom: 40px;
    }

    .child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-1 p{
        font-size:20px;
    }

    .child-or-adult-body-content .child-or-adult-cards-container .child-or-adult-card-2 p{
        font-size:20px;
    }
}