.css-wmw4vi-ReactDropdownSelect{
    border: solid 1px var(--text-color) !important;
    border-radius: 8px !important;
    height: 45px !important;
}

.css-wmw4vi-ReactDropdownSelect:hover, .css-wmw4vi-ReactDropdownSelect:focus-within{
    border: solid 1px var(--text-color) !important;
    box-shadow: none !important;
}

.css-wmw4vi-ReactDropdownSelect .css-w49n55-ClearComponent{
    line-height: 0px !important;
}