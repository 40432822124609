.parent-dashboard-screener-questions{
    padding: 0px;
}

.parent-dashboard-screener-questions .parent-dashboard-screener-questions-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px;
}

@media (max-width:576px){
    .parent-dashboard-screener-questions .parent-dashboard-screener-questions-body{
        padding: 50px 20px;
    }
}