.parent-profiles-page-container{
    padding: 0px;
}


.parent-profiles-page-container .parent-profiles-page-body {
  padding: 40px;
}

@media(max-width:576px){
  .parent-profiles-page-container .parent-profiles-page-body {
    padding: 40px 10px;
  }
  
}
