.upload_file_2_container {
    padding: 10px 0px;
    border: dashed 2px #030533;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .upload_file_2_container:hover {
    cursor: pointer;
  }
  