#bars:hover {
  cursor: pointer;
}

.parent-dashboard-sidebar-comp {
  width: 18%;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  /* transition: all 0.3s ease-in; */
}

.parent-dashboard-sidebar-comp .parent-dashboard-head-section {
  width: 100%;
  padding: 20px 30px;
}

.parent-dashboard-sidebar-comp .parent-dashboard-head-section img {
  margin-top: 30px;
}

.parent-dashboard-sidebar-comp .parent-dashboard-menu-container {
  margin-top: 30px;
  width: 100%;
  height: 90%;
  overflow-y: auto;
  padding-bottom: 80px;
}

/* width */

.parent-dashboard-sidebar-comp .parent-dashboard-menu-container {
  scrollbar-width: none;
}

.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-container::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-container::-webkit-scrollbar-thumb {
  background: #DEFFF3;
}

.parent-dashboard-sidebar-comp .parent-dashboard-menu-row {
  display: flex;
  align-items: center;
  padding: 18px 30px;
  color: #096E82;
}

.parent-dashboard-sidebar-comp .parent-dashboard-menu-row:hover {
  /* background-color: rgba(0, 0, 0, 0.01); */
  cursor: pointer;
  text-decoration: none;
}

.parent-dashboard-sidebar-comp .parent-dashboard-menu-row.active {
  /* background-color: rgba(0, 0, 0, 0.01); */
  cursor: pointer;
  color: #00c8d4;
  text-decoration: none;
}

.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-row
  .parent-dashboard-menu-col-1 {
  width: 40px;
}

.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-row
  .parent-dashboard-menu-col-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.parent-dashboard-sidebar-comp
  .parent-dashboard-menu-row
  .parent-dashboard-menu-col-2
  p {
  margin: 0px;
}

/* Sidebar Comp1 */
.parent-dashboard-sidebar-comp1 {
  width: 60px;
  background-color: #f0fafb;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  /* transition: all 0.3s ease-in; */
}

.parent-dashboard-sidebar-comp1 .parent-dashboard-head-section {
  width: 100%;
  padding: 20px;
}

.parent-dashboard-sidebar-comp1 .parent-dashboard-menu-container {
  margin-top: 40px;
  width: 100%;
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
}

.parent-dashboard-sidebar-comp1 .parent-dashboard-menu-row {
  display: flex;
  align-items: center;
  padding: 20px 0px;
  color: black;
}

.parent-dashboard-sidebar-comp1 .parent-dashboard-menu-row:hover {
  background-color: #00c8d4;
  cursor: pointer;
  color: white;
}

.parent-dashboard-sidebar-comp1
  .parent-dashboard-menu-row
  .parent-dashboard-menu-col-1 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.parent-dashboard-sidebar-comp1
  .parent-dashboard-menu-container::-webkit-scrollbar {
  width: 0px;
}

@media (max-width: 1024px) {
  .parent-dashboard-sidebar-comp {
    display: none !important;
  }

  .parent-dashboard-sidebar-comp1 {
    display: none !important;
  }
}
