.Signup-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 120px 0px 100px 0px;
}

.Signup-Page-Container .Signup-Page-Body {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signup-Page-Container .Signup-Page-Body h1 {
  color: #096E82;
  font-weight: 600;
  margin: 0px;
}

.Signup-Page-Container .Signup-Page-Body hr {
  height: 4px;
  width: 80px;
  background-color: #F99B9B;
}

.Signup-Page-Container .Signup-Page-Body .Signup-Page-tabs-container {
  margin-top: 30px;
  border-radius: 20px;
  background-color: #fdfdfd;
  border: solid 1px lightgray;
  display: flex;
}

.Signup-Page-Container .Signup-Page-Body .Signup-Page-tabs-container .tabs {
  padding: 10px 50px;
  color: black;
  text-align: center;
  border-radius: 20px;
  background: transparent;
  border: none;
}

.Signup-Page-Container
  .Signup-Page-Body
  .Signup-Page-tabs-container
  .active-tab {
  background: #F99B9B;
  padding: 10px 50px;
  color: white;
  text-align: center;
  border-radius: 20px;
}

.Signup-Page-Container .Signup-Page-Body .custom-radio-btn {
  width: 14px;
  height: 14px;
  background-color: #E2E2E4;
  border-radius: 50%;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Signup-Page-Container .Signup-Page-Body .custom-radio-btn .check-mark {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 0px 5px #F99B9B;
  display: none;
}

.Signup-Page-Container .Signup-Page-Body input[type="radio"] {
  display: none;
}

.Signup-Page-Container
  .Signup-Page-Body
   input:checked +
   .custom-radio-btn .check-mark {
  display: inline-block;
}

.Signup-Page-Container .Signup-Page-Body .Signup-btn {
  width: 100%;
  height: 50px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
}

.Signup-Page-Container .Signup-Page-Body .Signup-page-link {
  color: black;
  font-weight: 600;
  text-decoration: underline;
  font-family: 'CocoSharp bold';
}

@media (max-width: 768px) {
  .Signup-Page-Container .Signup-Page-Body {
    width: 60%;
  }
}

@media (max-width: 425px) {
  .Signup-Page-Container .Signup-Page-Body {
    width: 95%;
  }

  .Signup-Page-Container .Signup-Page-Body h1 {
    font-size: 32px;
  }
}
