.chat-component-index-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    height: calc(100vh - 280px);
}

@media (max-width:576px) {
    .chat-component-index-container{
        height: calc(100vh - 380px);
    }
}