.appointment-schedule-alert-btn{
    width: 100%;
    height: 35px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 5px;
    align-self: right;
    color: white;
    border: none;
}

.modal-content{
    border-radius: 12px !important;
}