.bc-profile-detail-container {
  padding: 0px;
}

.bc-profile-detail-container .bc-profile-detail-body {
  padding: 40px;
}

.bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1 {
  height: 310px;
  display: flex;
}

.bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1
  .bc-profile-detail-row-1-col-1 {
  height: 100%;
  width: 310px;
  background-repeat: no-repeat;
  background-size:cover;
  background-position:center
}

.bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1
  .bc-profile-detail-row-1-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 30px;
  width: calc(100% - 310px);
}

.bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1
  .bc-profile-detail-row-1-col-2
  .clinician-send-message-btn {
  width: 150px;
  height: 45px;
  margin-top: 0px;
  background-color: #096E82;
  font-family: 'CocoSharp regular';
  border-radius: 6px;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}



.bc-profile-detail-row-2 .bc-profile-detail-row-2-tab-bar{
  width:100%;
  height:60px;
  display:flex;
  align-items:flex-end;
}


.bc-profile-detail-row-2 .bc-profile-detail-row-2-tab-bar .bc-profile-detail-row-2-tab-bar-btn{
  border:none;
  font-family:'CocoSharp bold';
  background-color:transparent;
  height:40px;
  font-size:18px;
  padding:0px 35px;
}


@media (max-width: 1291px) {

  .bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1 {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .bc-profile-detail-container
  .bc-profile-detail-body
  .bc-profile-detail-row-1
  .bc-profile-detail-row-1-col-1 {
    height: 300px;
  }

  .bc-profile-detail-container
    .bc-profile-detail-body
    .bc-profile-detail-row-1
    .bc-profile-detail-row-1-col-2 {
    padding-left: 0px;
    margin-top: 20px;
    width: 100%
  }
}

@media (max-width: 768px) {
  .bc-profile-detail-container
    .bc-profile-detail-body
    .bc-profile-detail-row-1
    .bc-profile-detail-row-1-col-2 {
    padding-left: 0px;
    margin-top: 20px;
  }
}

@media (max-width:576px){
  .bc-profile-detail-container .bc-profile-detail-body {
    padding: 40px 10px;
  }

  .bc-profile-detail-row-2 .bc-profile-detail-row-2-tab-bar .bc-profile-detail-row-2-tab-bar-btn{
    padding:0px 10px;
  }
}

/* @media (min-width: 1440px) {
  .bc-profile-detail-container .bc-profile-detail-body {
    padding: 20px 3vw;
  }
}

@media (min-width: 1920px) {
  .bc-profile-detail-container .bc-profile-detail-body {
    padding: 20px 5vw;
  }
} */
