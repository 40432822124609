.cilinician-db-rating-form-container{
    padding: 0px;
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body {
  padding: 40px;
  padding-top: 100px;
}
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .rating-form-criteria{
    font-size: 12px;
    margin: 0px;
    color: gray;
    text-align: center;
}
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .rating-form-btn{
  width: 200px;
  height: 40px;
  margin-top: 0px;
  background-color: #F99B9B;
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2{
  display: table;
  margin-top: 30px;
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col1,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col2,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col3,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col4
{
  display: table-cell;
  padding: 0px 15px;
  height: 100%;
  width: calc(100%/3.5);
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col1,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col2,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col3
{
  border-right: solid 1px gray;
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col1 h5,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col2 h5,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col3 h5,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col4 h5{
  font-size: 16px !important;
  font-family: 'CocoSharp bold';
}

.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col4 input{
  width: 60px;
  height: 60px;
  border-radius: 5px;
  text-align: center;
  border: solid 1px var(--text-color);
  font-size: 20px;
  -moz-appearance: textfield !important;
}


/* Hiding arrows */
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col4 input::-webkit-outer-spin-button,
.cilinician-db-rating-form-container .cilinician-db-rating-form-body .clinician-db-rating-form-section2 .clinician-db-rating-form-section2-col4 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.assessment-form-mobile{
  display: none;
}

@media(max-width:1200px){
  .cilinician-db-rating-form-container .cilinician-db-rating-form-body {
    padding: 80px 20px;
  }
}

@media(max-width:991px){
  .cilinician-db-rating-form-container .cilinician-db-rating-form-body {
    display: none;
  }

  .assessment-form-mobile{
    display: block;
  }
}

