.procedure-fld-comp-container{
    width: 100%;
}

.procedure-fld-comp-container .procedure-fld-comp-title{
    font-size: 18px !important;
    font-family: 'CocoSharp regular';
    font-weight: 600;
    margin: 0px;
}

.procedure-fld-comp-container .procedure-fld-comp-des{
    font-size: 13px !important;
    font-family: 'CocoSharp regular';
    margin: 0px;
    margin-top: 10px !important;
}

.procedure-fld-comp-container .procedure-fld-comp-text-area{
    font-size: 14px !important;
    font-family: 'CocoSharp regular';
    margin: 0px;
    border: solid 1.5px black;
    border-radius: 12px;
    width: 100%;
    resize: none;
    margin: 20px 0px !important;
    padding: 15px !important;
}