.consultatin-tool-finish-btn{
    width: 250px;
    height: 50px;
    margin-top: 0px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    align-self: center;
    border: none;
  }
  