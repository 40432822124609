.pre-call-form-container {
  animation: displayAnimation;
  animation-duration: 1.5s;
}

.pre-call-form-btn {
  width: 50%;
  height: 50px;
  margin-top: 20px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  color: white;
  align-self: center;
  border: none;
}

#custom-table {
  border: 2px solid black;
  border-radius: 11px;
  border-spacing: 0;
  /* overflow: hidden; */
  border-collapse: separate;
}

#custom-table th,#custom-table td{
    width: 30px; 
    padding: 8px;
    border-bottom: 2px solid black; 
    border-right: 2px solid black;
}

#custom-table tr:last-child > td{
    border-bottom: none;
}

#custom-table td:nth-child(4),#custom-table th:nth-child(4){
    border-right: none;
}


#custom-table2 {
    border: 2px solid black;
    border-radius: 11px;
    border-spacing: 0;
    /* overflow: hidden; */
    border-collapse: separate;
  }
  
  #custom-table2 th,#custom-table2 td{
      width: 30px; 
      padding: 8px;
      border-bottom: 2px solid black; 
      border-right: 2px solid black;
  }
  
  #custom-table2 tr:last-child > td{
      border-bottom: none;
  }
  
  #custom-table2 td:nth-child(3),#custom-table2 th:nth-child(3){
      border-right: none;
  }

  .form-3-date-picker-class{
    width: 100%;
  }

  .form-3-date-picker-class .react-date-picker__wrapper{
    border: none;
    z-index: 100;
  }

@keyframes displayAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
