.fld_set {
  width: 100%;
  border: solid 2px #030533;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set .legend{
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: black;
  font-family: 'CocoSharp bold';
}


.fld_set .input, .fld_set_err .input, .fld_set_fcs .input  {
  border: none;
  padding-top: 0px;
  margin-top: 0px;
  background-color: transparent;
  font-family: 'CocoSharp regular';
}

.fld_set .input:focus, .fld_set_err .input:focus, .fld_set_fcs .input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}


.form-control:disabled{
  background-color: transparent !important;
}

/* on Focus */
.fld_set_fcs{
  width: 100%;
  border: solid 2px var(--secondary-color);
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_fcs .legend1  {
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: var(--secondary-color) !important;
  font-family: 'CocoSharp bold';
}

/* error */
.fld_set_err{
  width: 100%;
  border: solid 2px red;
  padding: 0px 5px 0px 5px;
  border-radius: 10px;
}

.fld_set_err .legend2{
  padding: 0px 5px 0px 5px;
  margin: 0px;
  width: auto;
  color: red !important;
  font-family: 'CocoSharp bold';
}