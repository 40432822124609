.dashboard-navbar-container{
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 40px !important;
}

.next-appointment-card h6,
.pre-appointment-card h6 {
  font-weight: bold;
}

.next-appointment-card {
  padding: 5px 15px;
  width: 240px;
  height: 85px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #096E82;
  margin: 0px 0px 0px 0px;
}

.next-appointment-card h6,
.next-appointment-card p {
  color: white;
  margin: 0px;
}

/* previous card */

.pre-appointment-card {
  padding: 5px 15px;
  width: 240px;
  height: 85px;
  border-radius: 9px;
  background-color: white;
  border: solid 2px #096E82;
  margin-right: 30px;
}

.pre-appointment-card h6,
.pre-appointment-card p {
  margin: 0px;
  color: #096E82 !important;
}

.bc-dashboard-navbar-col-2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.bc-dashboard-navbar-col-2 .dropdown-toggle::after {
  display: none !important;
}

.bc-dashboard-navbar-col-2 .dropdown-menu.show {
  width: 300px;
}

.bc-dashboard-navbar-col-2 .btn-link {
  border: none;
  outline: none;
  box-shadow: none;
}

.bc-dashboard-navbar-col-2 .btn-link:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.bc-dashboard-navbar-col-2 .dropdown-item:hover {
  background-color: transparent;
}

.bc-dashboard-navbar-col-2 #small_device_bars {
  display: none;
}


.bc-dashboard-navbar-col-2 #notifications-container{
  overflow-y: auto !important;
  scrollbar-width: none;
}

.bc-dashboard-navbar-col-2 #notifications-container::-webkit-scrollbar{
  width: 0em;
}

#bc-dashboard-navbar-join-btn {
  background-color: #096E82;
  /* padding: 5px 40px; */
  width: 240px;
  height: 40px;
  border-radius: 9px;
  outline: none;
  border: none;
  box-shadow: none;
  margin-top: 8px;
}

#bc-dashboard-navbar-join-btn:active,
#bc-dashboard-navbar-join-btn:focus {
  outline: none;
  border: none;
  box-shadow: none;
}


#bc-dashboard-navbar-join-btn1 {
  background-color: #096E82;
  opacity: 0.5;
  width: 240px;
  height: 40px;
  border-radius: 9px;
  outline: none;
  border: none;
  box-shadow: none;
  margin-top: 8px;
}

#bc-dashboard-navbar-join-btn1:active,
#bc-dashboard-navbar-join-btn1:focus {
  outline: none;
  border: none;
  box-shadow: none;
}


/* Small device side bar */

.ant-drawer-body.ant-drawer-body {
  padding: 0px;
}

.small-device-side-bar-menu {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  color: black;
}

.small-device-side-bar-menu.small-device-active{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  color: #00c8d4;
}

.small-device-side-bar-menu:hover {
  /* background-color: rgba(0, 0, 0, 0.01); */
  background-color: #00c8d4;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.small-device-side-bar-menu .small-device-side-bar-menu-col-1 {
  width: 20%;
}

.small-device-side-bar-menu .small-device-side-bar-menu-col-2 {
  width: 80%;
  display: flex;
  align-items: center;
}

.small-device-side-bar-menu .small-device-side-bar-menu-col-2 p {
  margin: 0px;
}

@media (max-width:1024px){
  .custom-row-reverse{
    flex-direction: column-reverse !important;
  }

  .bc-dashboard-navbar-col-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bc-dashboard-navbar-col-2 #small_device_bars {
    display: block;
  }
}

@media(max-width:576px){
  .pre-appointment-card{
    display: none;
  }

  .dashboard-navbar-container{
    padding-left: 10px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 425px) {
  .next-appointment-card h6,
  .pre-appointment-card h6 {
    font-size: 14px;
  }

  .next-appointment-card {
    padding: 10px 15px;
  }

  .next-appointment-card h6,
  .next-appointment-card p {
    font-size: 12px;
  }

  /* previous card */

  .pre-appointment-card {
    padding: 10px 15px;
  }

  .pre-appointment-card h6,
  .pre-appointment-card p {
    font-size: 12px;
  }
}
