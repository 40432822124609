.chat-contacts-list-comp{
    border: solid 1px #096E82;
    width: 25%;
    border-radius: 15px;
}

.chat-contacts-list-comp .chat-contacts-list-header{
    padding: 25px;
    background-color: #096E82;
    border-radius: 14px;
    color: white;
    font-size: 14px;
    font-family: 'CocoSharp bold';
}

.chat-contacts-list-comp .chat-contacts-list-header-1{
    display: flex;
    align-items: center;
}

.chat-contacts-list-comp .chat-contacts-list-header-1 .chat-contacts-list-header-card{
    color: white;
    font-size: 14px;
    font-family: 'CocoSharp bold';
    width:50%;
    cursor:pointer;
    padding: 25px
}

.chat-contacts-list-comp .chat-contacts-list-body{
    padding: 20px;
    height: calc(100% - 80px);
    overflow-y: auto;
    scrollbar-width: none;
}
  
.chat-contacts-list-comp .chat-contacts-list-body::-webkit-scrollbar {
    width: 3px;
}
  
  /* Track */
.chat-contacts-list-comp .chat-contacts-list-body::-webkit-scrollbar-track {
    background: transparent;
}
  
  /* Handle */
.chat-contacts-list-comp .chat-contacts-list-body::-webkit-scrollbar-thumb {
    background: #096E82;
}

@media (max-width:1440px){
    .chat-contacts-list-comp{
        width: 26%;
    }

    .chat-contacts-list-comp .chat-contacts-list-body{
        padding: 10px;
    }
}

@media (max-width:1280px){
    .chat-contacts-list-comp{
        width: 28%;
    }
}

@media (max-width:768px){
    .chat-contacts-list-comp{
        display: none;
    }
}