.upload_image_container {
  padding: 10px 20px;
  border: dashed 2px #030533;
  width: 100%;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload_image_container:hover {
  cursor: pointer;
}

.upload_image_container .upload_image_col-1 {
  display: flex;
  align-items: center;
}
