.cilinicians-profiles-page-container{
    padding: 0px;
}


.cilinicians-profiles-page-container .cilinicians-profiles-page-body {
  padding: 40px;
}

@media(max-width:576px){
  .cilinicians-profiles-page-container .cilinicians-profiles-page-body {
    padding: 40px 10px;
  }
  
}

/* @media(min-width:1440px){
    .cilinicians-profiles-page-container .cilinicians-profiles-page-body{
      padding: 20px 3vw;
    }
  }
  
  @media(min-width:1920px){
    .cilinicians-profiles-page-container .cilinicians-profiles-page-body{
      padding: 20px 5vw;
    }
  } */