.program-card-container{
    height:80px;
    width:100%;
    padding:0px 20px;
    margin-bottom:25px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-left: solid 5px #F99B9B;
    /* border-top: solid 6px #F99B9B; */
    border-radius: 10px;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.1);
}

.program-card-container .child-card-select-btn{
    width: 100px;
    height: 40px;
    margin-top: 0px;
    background-color: #F99B9B;
    font-family: "CocoSharp regular";
    border-radius: 7px;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}