.parent-dashboard-additional-resources {
  padding: 0px;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body {
  padding: 40px;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #096E82;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  h5 {
  color: #096E82;
  margin-top: 0px;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  .parent-dashboard-additional-resources-videos {
  display: flex;
  width: 100%;
  overflow-y: auto;
  flex-wrap: wrap;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  .parent-dashboard-additional-resources-videos
  .video-continer {
  width: 225px;
  height: 160px;
  margin: 20px 20px 0px 0px;
  border-radius: 8px;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  .parent-dashboard-additional-resources-videos
  .video-continer
  img {
  max-width: 100%;
  height: 160px;
  border-radius: 8px;
  cursor: pointer;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  .parent-dashboard-additional-resources-notes-section {
  display: flex;
  flex-wrap: wrap;
}

.parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body
  h2 {
  font-size: 28px;
  font-weight: 700;
  color: #096E82;
}

@media(max-width: 576px){
  .parent-dashboard-additional-resources
  .parent-dashboard-additional-resources-body {
    padding: 40px 10px;
  }
}


@media (max-width: 425px) {
  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-videos {
    display: flex;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-videos
    .video-continer {
    width: 225px;
    height: 160px;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-videos
    .video-continer
    img {
    height: 160px;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-notes-section {
    display: flex;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    h1 {
    font-size: 32px;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    h2 {
    font-size: 24px;
  }
}

@media (max-width: 375px) {
  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-videos
    .video-continer {
    width: 225px;
    height: 160px;
  }

  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body
    .parent-dashboard-additional-resources-videos
    .video-continer
    img {
    height: 160;
  }
}
/* 
@media (min-width: 1440px) {
  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body {
    padding: 20px 3vw;
  }
}

@media (min-width: 1920px) {
  .parent-dashboard-additional-resources
    .parent-dashboard-additional-resources-body {
    padding: 20px 5vw;
  }
} */
