.dashboard-appointments-container {
    padding: 0px;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body {
    padding: 40px;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    h1 {
    font-size: 36px;
    font-weight: 700;
    color: #096E82;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    .dashboard-appointments-upcoming {
    display: flex;
    width: 100%;
    overflow-y: auto;
    flex-wrap: wrap;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    .dashboard-appointments-upcoming
    .video-continer {
    width: 225px;
    height: 160px;
    margin: 20px 20px 0px 0px;
    border-radius: 8px;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    .dashboard-appointments-upcoming
    .video-continer
    img {
    max-width: 100%;
    height: 160px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    .dashboard-appointments-history {
    display: flex;
    flex-wrap: wrap;
  }
  
  .dashboard-appointments-container
    .dashboard-appointments-body
    h2 {
    font-size: 36px;
    color: #096E82;
  }
  
  @media(max-width: 576px){
    .dashboard-appointments-container
    .dashboard-appointments-body {
      padding: 40px 10px;
    }
  }
  
  @media (max-width: 425px) {
    .dashboard-appointments-container
      .dashboard-appointments-body
      .dashboard-appointments-upcoming {
      display: flex;
    }
  
  
    .dashboard-appointments-container
      .dashboard-appointments-body
      .dashboard-appointments-history {
      display: flex;
    }
  
    .dashboard-appointments-container
      .dashboard-appointments-body
      h1 {
      font-size: 32px;
    }
  
    .dashboard-appointments-container
      .dashboard-appointments-body
      h2 {
      font-size: 24px;
    }
  }
  
  /* @media (min-width: 1440px) {
    .dashboard-appointments
      .dashboard-appointments-body {
      padding: 20px 3vw;
    }
  }
  
  @media (min-width: 1920px) {
    .dashboard-appointments
      .dashboard-appointments-body {
      padding: 20px 5vw;
    }
  } */
  