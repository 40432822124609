
.animated-toggle-tabs-container {
    overflow: hidden;
    border-radius: 8px;
    width: 50%;
    height: 50px;
    background-color: #fdfdfd;
    border: solid 1px #096E82;
    display: flex;
    position: relative;
  }
  
  .animated-toggle-tabs-container.default::after{
    content: "";
    position: absolute;
    border-radius: 8px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 50%;
    background-color: #096E82;
    color: white !important;
  }
  
  .animated-toggle-tabs-container.left::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 50%;
    background-color: #096E82;
    animation: leftAnimation 0.5s;
  }
  
  .animated-toggle-tabs-container.right::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50%;
    bottom: 0px;
    border-radius:8px;
    background-color: #096E82;
    animation: rightAnimation 0.5s;
  }
  
  
  
  .animated-toggle-tabs-container .tabs {
    width: 50%;
    color: black;
    text-align: center;
    background: transparent;
    border: none;
    z-index: 100;
    animation: defaultColor 0.5s;
  }
  
  .animated-toggle-tabs-container .active-tab {
    color: white;
    animation: activeColor 0.5s;
    text-align: center;
  }
  
  
  .animated-toggle-tabs-container .active-default {
    color: white;
    width: 50%;
    text-align: center;
    background: transparent;
    border: none;
    z-index: 100;
  }