.mchat-questions-screen-container{
    width: 100%;
    height: 750px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.mchat-questions-screen-container .mchat-questions-screen-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 80%;
    padding: 120px 20px;
}


.mchat-questions-screen-container .mchat-floated-circle{
    position:absolute;
}

.mchat-questions-screen-container .mchat-floated-circle.circle-1{
    right: 0px;
    z-index:-1;
    bottom:-450px;
}

.mchat-questions-screen-container .mchat-floated-circle.circle-2{
    left: 0px;
    z-index:-1;
    bottom:-100px;
}

.mchat-questions-screen-container .mchat-questions-screen-content .mchat-questions-start-btn{
    width: 250px;
    height: 50px;
    margin-top: 15px;
    background-color: #F99B9B;
    border-radius: 8px;
    color: white;
    align-self: center;
    border: none;
    margin-top: 80px;
}

@media (max-width:991px){

    .mchat-questions-screen-container .mchat-questions-screen-content{
        padding: 50px 20px;
        width: 100%;
    }
    
    .mchat-questions-screen-container .mchat-floated-circle{
        max-width:70%;
    }

    .mchat-questions-screen-container .mchat-floated-circle.circle-1{
        right: -150px;
    }
}

@media (max-width:576px){


    .mchat-questions-screen-container{
        height:100%;
    }

    .mchat-questions-screen-container .mchat-floated-circle.circle-1{
        bottom: -200px;
    }

    .mchat-questions-screen-container .mchat-floated-circle.circle-2{
        top: 0px;
    }
}