.parent-profile-detail-container {
    padding: 0px;
  }
  
  .parent-profile-detail-container .parent-profile-detail-body {
    padding: 40px;
  }
  
  .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1 {
    height: 310px;
    display: flex;
  }
  
  .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1
    .parent-profile-detail-row-1-col-1 {
    height: 100%;
    width: 310px;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center
  }
  
  .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1
    .parent-profile-detail-row-1-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 30px;
    width: calc(100% - 310px);
  }
  
  .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1
    .parent-profile-detail-row-1-col-2
    .parent-send-message-btn {
    width: 150px;
    height: 45px;
    margin-top: 0px;
    background-color: #096E82;
    border-radius: 10px;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .parent-profile-detail-row-2 .parent-profile-detail-row-2-tab-bar{
    width:100%;
    height:60px;
    display:flex;
    align-items:flex-end;
  }


  .parent-profile-detail-row-2 .parent-profile-detail-row-2-tab-bar .parent-profile-detail-row-2-tab-bar-btn{
    border:none;
    font-family:'CocoSharp bold';
    background-color:transparent;
    height:55px;
    font-size:18px;
    padding:0px 35px;
  }

  @media (max-width: 1291px) {

    .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1 {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .parent-profile-detail-container
    .parent-profile-detail-body
    .parent-profile-detail-row-1
    .parent-profile-detail-row-1-col-1 {
      height: 300px;
    }

    .parent-profile-detail-container
      .parent-profile-detail-body
      .parent-profile-detail-row-1
      .parent-profile-detail-row-1-col-2 {
      padding-left: 0px;
      margin-top: 20px;
      width: 100%
    }
  }

  @media (max-width:576px){
    .parent-profile-detail-container .parent-profile-detail-body {
      padding: 40px 10px;
    }
  }
  
  /* @media (min-width: 1440px) {
    .parent-profile-detail-container .parent-profile-detail-body {
      padding: 20px 3vw;
    }
  }
  
  @media (min-width: 1920px) {
    .parent-profile-detail-container .parent-profile-detail-body {
      padding: 20px 5vw;
    }
  }
   */