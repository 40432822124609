.Payment-Confirm-Page-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 120px 0px;
}

.Payment-Confirm-Page-Container .Payment-Confirm-Page-Body {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Payment-Confirm-Page-Container .Payment-Confirm-Page-Body h1 {
  color: #096E82;
  font-family: 'CocoSharp bold';
  font-weight: bolder;
  font-size: 48px;
}

.Payment-Confirm-Page-Container .Payment-Confirm-Page-Body hr {
  height: 4px;
  margin-top: 5px;
  width: 80px;
  background-color: #AEDFE5;
  margin-bottom: 40px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-1
  h3 {
  color: #F99B9B;
  font-weight: 700;
  font-size: 30px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-1
  h4 {
  font-weight: 400;
  font-size: 25px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-1
  .Payment-Confirm-btn {
  width: 250px;
  height: 50px;
  margin-top: 20px;
  border: none;
  background-color: #F99B9B;
  border-radius: 8px;
  color: white;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-1
  .Payment-Confirm-btn:last-child {
  margin-left: 20px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-2
  .Payment-detail {
  width: 70%;
  padding: 20px;
  background-color: #f5f5f7;
  border-radius: 10px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-2
  .Payment-detail
  h6 {
  font-size: 15px;
  font-weight: 600;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-2
  .Payment-detail
  p {
  margin: 0px;
  font-size: 15px;
}

.Payment-Confirm-Page-Container
  .Payment-Confirm-Page-Body
  .Payment-Confirm-Page-Sec-2
  .Payment-detail
  hr {
  height: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 50px;
}

@media (max-width: 1024px) {
  .Payment-Confirm-Page-Container .Payment-Confirm-Page-Body {
    width: 90%;
  }

  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-2
    .Payment-detail {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .Payment-Confirm-Page-Container .Payment-Confirm-Page-Body {
    width: 95%;
  }

  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-1
    .Payment-Confirm-btn {
    width: 200px;
    height: 50px;
  }

  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-2
    .Payment-detail {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-1 {
    margin-top: 40px;
  }

  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-1
    .Payment-Confirm-btn {
    width: 100%;
  }

  .Payment-Confirm-Page-Container
    .Payment-Confirm-Page-Body
    .Payment-Confirm-Page-Sec-1
    .Payment-Confirm-btn:last-child {
    margin-left: 0px;
  }
}
