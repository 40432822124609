.fld_set1 {
    width: 100%;
    border: solid 2px var(--secondary-color);
    padding: 0px 5px 0px 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  
  /* .fld_set1 .legend{
    padding: 0px 5px 0px 5px;
    margin: 0px;
    width: auto;
    color: black;
  } */
  
  
  .fld_set1 .input1, .fld_set_err1 .input1, .fld_set_fcs1 .input1  {
    border: none;
    padding-top: 0px;
    margin-top: 5px;
    font-family: 'CocoSharp regular';
  }
  
  .fld_set1 .input1:focus, .fld_set_err1 .input1:focus, .fld_set_fcs1 .input1:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  
  
  .form-control:disabled{
    background-color: transparent !important;
  }
  
  /* on Focus */
  .fld_set_fcs1{
    width: 100%;
    border: solid 2px var(--secondary-color);
    padding: 0px 5px 0px 5px;
    border-radius: 10px;
  }
  
  .fld_set_fcs1 .legend1  {
    padding: 0px 5px 0px 5px;
    margin: 0px;
    width: auto;
    color: var(--secondary-color) !important;
  }
  
  /* error */
  .fld_set_err1{
    width: 100%;
    border: solid 2px red;
    padding: 0px 5px 0px 5px;
    border-radius: 10px;
  }
  
  .fld_set_err1 .legend2{
    padding: 0px 5px 0px 5px;
    margin: 0px;
    width: auto;
    color: red !important;
  }