.parent-video-calling-page-container {
    padding: 0px;
    width: 100%;
}

.parent-video-calling-page-container .parent-video-calling-page-body {
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
}

@media(max-width:576px){
  .parent-video-calling-page-container .parent-video-calling-page-body {
    padding: 40px 10px;
  }
}